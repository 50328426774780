import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './logo.svg';
import './App.css';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Feedback from './pages/Feedback';
import Sidebar from './Component/Sidebar';
import Header from './Component/Header';
import Setting from './pages/Setting';
import Drug from './pages/Drug';
import Procedure from './pages/Procedure';
import Guidelines from './pages/Guidelines';
import Checklist from './pages/Checklist';
// import Footer from './Component/Footer';
import Usage_tracking from './pages/Usage_tracking';

import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

const access_token = localStorage.getItem('access_token');
var pathname = window.location.pathname;

function App() {

  return (
    <div>
      <ToastContainer />
      <Router>
        {!access_token && <Redirect to="/" />}
        
        {access_token == null && (pathname == '/')}
        {access_token && <Redirect to= {pathname} />}

        {access_token ? (
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route path='/feedback' component={Feedback} />
            <Route path='/sidebar' component={Sidebar} />
            <Route path='/header' component={Header} />
            <Route path='/setting' component={Setting} />
            <Route path='/drug' component={Drug} />
            <Route path='/procedure' component={Procedure} />
            <Route path='/guidelines' component={Guidelines} />
            <Route path='/checklist' component={Checklist} />
            <Route path='/usage_tracking/' component={Usage_tracking} />
          </Switch>
        ) : (
            <Switch>
              <Route exact={true} path='*' component={Login} />
            </Switch>
          )}
      </Router>
    </div>
  );
}

export default App;
