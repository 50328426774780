import React, { Component, useState, useEffect } from "react";
import Modal from 'react-modal';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Sidebar from '../Component/Sidebar';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { userService } from '../_services';
import { config } from '../config/config'
import Loader from '../Component/Loader'

const Setting = (props) => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [image, setImage] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [profilePicUrl, setProfilePicUrl] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [inputType, setInputType] = useState("password");
    const [inputType2, setInputType2] = useState("password");

    useEffect(() => {
        let img = localStorage.getItem('profile_image');
        if (img) setProfilePicUrl(config.imgBaseUrl + img);
    }, []);

    const submit = () => {
        if (currentPassword === '') {
            toast.error("Please Enter current password");
        } else if (newPassword === '') {
            toast.error("Please Enter new password");
        }
        //  else if (!/^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{9,}$/.test(newPassword) || /^[0]+$/.test(newPassword)) {
        //     toast.error("Password Contain Atleast 9 Characters, 1-Letter and 1-Number");
        // } 
        else if (confirmPassword === '') {
            toast.error("Please Enter confirm password");
        } else if (confirmPassword !== newPassword) {
            toast.error("New-Password and Confirm-Password doesn't match");
        } else {
            let params = {
                'old_password': currentPassword,
                'new_password': newPassword
            };
            setisLoading(true);
            console.log(params);
            userService.changePassword(params).then(function (response) {
                toast.success(response.data.message);
                setisLoading(false);
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                localStorage.setItem("user_id", response.data.admin._id)
                localStorage.setItem("access_token", response.data.access_token)
                setTimeout(() => {
                    window.location.href = '/setting';
                }, 1000);
            }).catch(function (error) {
                if (error.response) {
                    userService.handleError(error);
                    // toast.error(error.response.data.message)
                }
                setisLoading(false);
            });
        }
    }

    const uploadProfileImage = () => {
        if (image === '' && !profilePicUrl) {
            toast.error("Please select profile picture");
        } else if (image === '' && profilePicUrl) {
            setisLoading(true);
            setTimeout(() => {
                setisLoading(false);
                toast.success("Profile updated successfully");
            }, 1500);
        } else {
            let params = {
                'profile_image': image
            };
            var formdata = new FormData();
            formdata.append('profile_image', image);
            setisLoading(true)
            console.log(params)
            userService.uploadProfileImage(formdata).then(function (response) {
                localStorage.setItem('profile_image', response.data.user.profile_image)
                setProfilePicUrl(config.imgBaseUrl + response.data.user.profile_image);
                setisLoading(false);
                toast.success("Profile updated successfully");
                // toast.success(response.data.message);
                setTimeout(() => {
                    // window.location.href = '/setting';
                }, 1000);
            }).catch(function (error) {
                if (error.response) {
                    userService.handleError(error);
                    // toast.error(error.response.data.message)
                }
                setisLoading(false);
            });
        }
    }

    function handleImageChange(e) {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
            setProfilePicUrl(URL.createObjectURL(e.target.files[0]))
        }
    }

    return (
        <div>
            {isLoading && <Loader />}
            <div className="Wrapper">
                <Sidebar />
                <div class="main">

                    <Header profileUrl={profilePicUrl}/>
                    <div className="main-div">
                        <section className="setting-page">
                            <div className="container-fluid">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h3>Update Profile Picture</h3>
                                        <div className="profile-update text-center">
                                            <input type="file" onChange={(e) => handleImageChange(e)} />
                                            <img src={profilePicUrl ? profilePicUrl : "./images/profile_upload.png"} width="200" />
                                            {/* <img src={config.baseurl+img} width="200" /> */}
                                            <p>Choose Profile Picture</p>
                                            <a onClick={() => uploadProfileImage()}>UPDATE</a>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <h3>Change Password</h3>
                                        <div className="change-password-box">
                                            <div className="passwor-change-filled">
                                                <i className={"password-eye " + (inputType === "password" ? "fa fa-eye-slash" : "fa fa-eye")} onClick={() => ((inputType === 'text') ? setInputType('password') : setInputType('text'))}></i>
                                                <div class="form-group">
                                                    <p>Current Password</p>
                                                    <label for="formGroupExampleInput">Password</label>
                                                    <input type={inputType} class="form-control" id="formGroupExampleInput" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} placeholder="Enter your password" />
                                                </div>
                                                <i className={"password-eye " + (inputType2 === "password" ? "fa fa-eye-slash" : "fa fa-eye")} onClick={() => ((inputType2 === 'text') ? setInputType2('password') : setInputType2('text'))}></i>
                                                <div class="form-group">
                                                    <p>New Password</p>
                                                    <label for="formGroupExampleInput">New Password</label>
                                                    <input type={inputType2} class="form-control" id="formGroupExampleInput" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="Enter your new password" />
                                                </div>
                                                <div class="form-group">
                                                    <label for="formGroupExampleInput">Confirm New Password</label>
                                                    <input type="password" class="form-control" id="formGroupExampleInput" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Re-enter your new password" />
                                                </div>
                                            </div>
                                            <p className="text-center"><a onClick={() => submit()}>SUBMIT</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Setting