
import React, { Component, useEffect, useState } from "react";
import Modal from 'react-modal';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Sidebar from '../Component/Sidebar';
import { userService } from '../_services';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';
import Loader from '../Component/Loader'
import Paginate from '../Component/Pagination';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const Procedure = (props) => {
    const [procedure, setProcedure] = useState([]);
    const [procedureType, setProcedureType] = useState([]);
    const [procedureName, setProcedureName] = useState('');
    const [search, setSearch] = useState('');
    const [procedureTypeId, setProcedureTypeId] = useState('-1');
    const [singleProcedure, setSingleProcedure] = useState('');
    const [status, setStatus] = useState('');
    const [dateFormet, setDateFormet] = useState('');
    const [timeFormet, setTimeFormet] = useState('');
    const [conditions, setConditions] = useState(['']);
    const [isProcedureDisable, setIsProcedureDisable] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const timezone = jstz.determine();
    const tz = timezone.name();
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [size, setSize] = useState(10);
    const [modalIsOpendelete, setIsOpenedelete] = useState(false);

    useEffect(() => {
        getProcedureList('', 0, size);
        getProcedureTypeList();
    }, []);

    const getProcedureList = (searchTxt, pageNo, size) => {
        setisLoading(true);
        userService.procedureList(searchTxt, pageNo, size).then(function (response) {
            setProcedure(response.data.all_procedure_list);
            setisLoading(false);
            setPageCount(response.data.result_count);
        }).catch(function (error) {
            setisLoading(false);
            setProcedure([]);
            userService.handleError(error);
            // toast.error(error.response.data.message);
        });
    }
    const handlePageChange = (page) => {
        console.log(pageCount);
        setPage(page);
        getProcedureList('', page, size);
    };
    const getProcedureTypeList = () => {
        setisLoading(true);
        userService.procedureTypeList().then(function (response) {
            setProcedureType(response.data.data);
            setisLoading(false);
        }).catch(function (error) {
            setisLoading(false);
            userService.handleError(error);
            setProcedureType([]);
            // toast.error(error.response.data.message);
        });
    }

    function onSearchTextChange(value) {
        setSearch(value);
        getProcedureList(value, 0, size);
        setPage(0);
    }

    const handleSelectedProcedureType = (e) => {
        setProcedureTypeId(e.target.value);
    }

    var subtitle;
    const [modalIsOpenadddrug, setIsOpenadddrug] = React.useState(false);
    function openModaladddrug() {
        setIsOpenadddrug(true);
        setProcedureName('');
        setProcedureTypeId('-1');
        setConditions(['']);
    }

    function afterOpenModaladddrug() {
        subtitle.style.color = '#f00';
    }

    function closeModaladddrug() {
        setIsOpenadddrug(false);
    }

    const [modalIsOpenEditDrug, setIsmodalIsOpenEditDrug] = React.useState(false);

    function openModalEditdrug(data) {
        setSingleProcedure(data);
        setProcedureName(data.procedure_name);
        setProcedureTypeId(data.procedure_type._id);
        setConditions(data.procedure_conditions_for_high_risk_of_bleeding);
        setIsmodalIsOpenEditDrug(true);
        setIsOpenviewfeedback(false);
    }
    function closeModalEditdrug() {
        setIsmodalIsOpenEditDrug(false);
    }

    var subtitle;
    const [modalIsOpenviewfeedback, setIsOpenviewfeedback] = React.useState(false);
    function openModalviewfeedback(data) {
        setSingleProcedure(data);
        const dateToFormat = data.createdAt;
        setTimeFormet(dateToFormat);
        var aa = moment.tz(data.createdAt, tz)
        setTimeFormet(aa);
        setIsOpenviewfeedback(true);
        setIsProcedureDisable(data.status === 'ACT' ? true : false)
    }

    function afterOpenModalviewfeedback() {
        subtitle.style.color = '#f00';
    }

    function closeModalviewfeedback() {
        setIsOpenviewfeedback(false);
    }

    function handleValueChange(event, index) {
        const values = [...conditions];
        values[index] = event.target.value;
        setConditions(values);
    }

    const handleAdd = (e) => {
        setConditions((prevState) =>
            [...prevState, '']
        );
    }

    function handleRemove(i, j) {
        const values = [...conditions];
        values.splice(i, 1);
        setConditions(values);
    }

    const addProcedure = () => {
        if (procedureName === '') {
            toast.error("Please enter procedure name");
        } else if (procedureTypeId === '-1') {
            toast.error("Please select procedure type");
        } else if (['', null, undefined].some(val => conditions.includes(val))) {
            toast.error('Please enter condition');
        } else {
            let params = {
                'procedure_name': procedureName,
                'procedure_type': procedureTypeId,
                'procedure_conditions_for_high_risk_of_bleeding': conditions
            };
            setisLoading(true);
            console.log(params);
            userService.addProcedure(params).then(function (response) {
                setisLoading(false);
                toast.success(response.data.message);
                setTimeout(() => {
                    window.location.href = '/procedure';
                }, 1000);
            }).catch(function (error) {
                if (error.response) {
                    userService.handleError(error);
                    // toast.error(error.response.data.message)
                }
                setisLoading(false);
            });
        }
    }

    const editProcedure = () => {
        if (procedureName === '') {
            toast.error("Please enter procedure name");
        } else if (procedureTypeId === '-1') {
            toast.error("Please select procedure type");
        } else if (['', null, undefined].some(val => conditions.includes(val))) {
            toast.error('Please enter condition');
        } else {
            let params = {
                'procedure_id': singleProcedure._id,
                'procedure_name': procedureName,
                'procedure_type': procedureTypeId,
                'procedure_conditions_for_high_risk_of_bleeding': conditions
            };
            setisLoading(true);
            console.log(params);
            userService.editProcedure(params).then(function (response) {
                setisLoading(false);
                toast.success(response.data.message);
                setIsmodalIsOpenEditDrug(false);
                getProcedureList('', page, size);
            }).catch(function (error) {
                if (error.response) {
                    userService.handleError(error);
                    // toast.error(error.response.data.message)
                }
                setisLoading(false);
            });
        }
    }

    function handleStatus(id, status) {
        // debugger
        setisLoading(true);
        setStatus(status)
        // setSingleProcedure(data);
        let tmpStatus = status === 'ACT' ? 'DIS' : 'ACT'
        let params = {
            procedure_id: id,
            procedure_status: tmpStatus
        };
        // debugger
        userService.enableDisableProcedure(params).then(function (response) {
            //  debugger
            setIsProcedureDisable(!isProcedureDisable);
            setSingleProcedure(response.data.procedure);
            getProcedureList('', page, size);
            setisLoading(false);
        }).catch(function (error) {
            setisLoading(false);
            userService.handleError(error);
            // toast.error(error.response.data.message);
        });
    }

    const [deletedData, setDeletedData] = useState('');
    function closeModaldelete() {
        setIsOpenedelete(false);
    }

    function afterOpenModalDelete() {
        subtitle.style.color = '#f00';
    }

    function openModaldelete(data) {
        setDeletedData(data);
        setIsOpenedelete(true);
    }

    function deleteProcedure(id) {
        setisLoading(true);
        let params = {
            procedure_id: id,
            procedure_status: 'DEL'
        };
        userService.enableDisableProcedure(params).then(function (response) {
            closeModaldelete();
            setisLoading(false);
            toast.success("Procedure deleted successfully")
            getProcedureList('', page, size);
        }).catch(function (error) {
            userService.handleError(error);
            // toast.error(error.response.data.message);
            setisLoading(false);
        });
    }

    return (
        <div>
            {isLoading && <Loader />}
            <div className="Wrapper">
                <Sidebar />
                <div class="main">

                    <Header />
                    <div className="main-div">
                        <div className="drug-page-heading">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-5">
                                        <h3>Procedures</h3>
                                    </div>
                                    <div className="col-md-7 d-flex">
                                        <div class="input-group input-group-md category-search mr-4">
                                            <input type="text" class="form-control" aria-label="Large" aria-describedby="inputGroup-sizing-sm" onChange={(e) => onSearchTextChange(e.target.value)} placeholder="Search by name" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-lg"><i class="fa fa-search" aria-hidden="true"></i></span>
                                            </div>
                                        </div>
                                        <a onClick={() => openModaladddrug()} className="add-new-btn"><i class="fa fa-plus mr-2" aria-hidden="true"></i> ADD NEW</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section class="category-list">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th className="name-th">Name</th>
                                            <th className="type-th">Type</th>
                                            <th className="type-add">Added On</th>
                                            <th className="status-th">Status</th>
                                            <th className="action-th">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {procedure.length > 0 ? procedure.map((data, index) => {
                                            const dateToFormat = data.createdAt;
                                            var aa = moment.tz(data.createdAt, tz)
                                            return (
                                                <tr>
                                                    <td className="text-center">{(page * size) + (index + 1)}</td>
                                                    <td><p className="procedure-name-td">{data.procedure_name}</p></td>
                                                    <td>{data.procedure_type.type_name}</td>
                                                    <td>{moment(data.createdAt).format("DD MMMM, YYYY")}</td>
                                                    <td class={data.status === 'ACT' ? "active-status" : 'deactive-status'}>{data.status == 'ACT' ? "Enabled" : "Disabled"}</td>
                                                    <td><div class="dropdown">
                                                        <button class="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Select <i class="fa fa-angle-down ml-2" aria-hidden="true"></i>
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item one-item" onClick={() => openModalviewfeedback(data)}>View Procedure</a>
                                                            <a class="dropdown-item second-item" onClick={() => openModalEditdrug(data)}>Edit Procedure</a>
                                                            <a class="dropdown-item third-item" onClick={() => handleStatus(data._id, data.status)}>{data.status == 'ACT' ? "Disable" : 'Enable'} Procedure</a>
                                                            <a className="dropdown-item fourth-item" onClick={() => openModaldelete(data)}>Delete Procedure</a>
                                                        </div>
                                                    </div></td>
                                                </tr>
                                            );
                                        })
                                            :
                                            <tr><td colSpan={6} className="text-center"><b>{search ? "No Procedure found" : "No Procedure added yet"}</b></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </section>
                        {/* <div className="pagination-list-area">
                            <p>1-10 of 10 Listing</p>
                            <div className="pagination">
                                <a href="#">&laquo;</a>
                                <a href="#">&#8249;</a>
                                <a class="active" href="#">1</a>
                                <a href="#">2</a>
                                <a href="#">&#8250;</a>
                                <a href="#">&raquo;</a>
                            </div>
                        </div> */}
                        <div className="pagination-list-area">
                            <div className="pagination">
                            </div>
                            {!isLoading ? <p>{(page * size) + 1} - {(page * size) + procedure.length} of {pageCount} Listing</p> : <p></p>}
                            <div className="pagination">
                            </div>
                            <Paginate count={pageCount} activePage={page} handlePageChange={(page) => handlePageChange(page)} perPageEntries={size} />

                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

            <Modal
                isOpen={modalIsOpenadddrug}
                onAfterOpen={afterOpenModaladddrug}
                onRequestClose={closeModaladddrug}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="edit-modal">
                    <div class="modal-header">
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Add New Procedure</h2>
                        <button className="cross-btn" onClick={closeModaladddrug}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div class="modal-body">
                        <div className="change-password-box">
                            <div className="passwor-change-filled">
                                <div class="form-group">
                                    <label for="formGroupExampleInput">Procedure Name</label>
                                    <input type="text" class="form-control" value={procedureName} onChange={e => setProcedureName(e.target.value)} id="formGroupExampleInput" placeholder="Enter Procedure Name" />
                                </div>
                                <div class="form-group">
                                    <label for="formGroupExampleInput">Procedure Type</label>
                                    <select class="form-control m-0" value={procedureTypeId} onChange={(e) => handleSelectedProcedureType(e)} id="exampleFormControlSelect1">
                                        <option id={"procedure-type-0"} value={'-1'}> Select procedure type</option>
                                        {procedureType.map(function (item, i) {
                                            return (<option id={"procedure-type-" + (i + 1)} value={item._id}> {item.type_name} </option>)
                                        })}
                                    </select>
                                    <i class="fa fa-angle-down procedure-type" aria-hidden="true"></i>
                                </div>
                                <div class="form-group bleeding_list">
                                    <label for="formGroupExampleInput">Conditions for 'Suspected High risk of Bleeding?'</label>
                                    {conditions.map((condition, idx) => {
                                        return (<div className="feedback-conditions">{idx !== 0 && <span onClick={() => handleRemove(idx)} className="add-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></span>}<input type="text" class="form-control" value={condition} onChange={(e) => handleValueChange(e, idx)} id="formGroupExampleInput" placeholder="Enter Condition" /></div>);
                                    })}
                                </div>
                                <p className="text-right"><a className="condition-add" onClick={() => handleAdd()}>+ Add More Condition</a></p>
                            </div>
                            <p className="text-center add-drug-submit"><a onClick={() => addProcedure()}>SUBMIT</a></p>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpenEditDrug}
                onAfterOpen={afterOpenModaladddrug}
                onRequestClose={closeModalEditdrug}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="edit-modal">
                    <div class="modal-header">
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Edit Procedure</h2>
                        <button className="cross-btn" onClick={closeModalEditdrug}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div class="modal-body">
                        <div className="change-password-box">
                            <div className="passwor-change-filled">
                                <div class="form-group">
                                    <label for="formGroupExampleInput">Procedure Name</label>
                                    <input type="text" class="form-control" value={procedureName} onChange={e => setProcedureName(e.target.value)} id="formGroupExampleInput" placeholder="Enter Procedure Name" />
                                </div>
                                <div class="form-group">
                                    <label for="formGroupExampleInput">Procedure Type</label>
                                    <select class="form-control m-0" value={procedureTypeId} onChange={(e) => handleSelectedProcedureType(e)} id="exampleFormControlSelect1">
                                        <option id={"procedure-type-0"} value={'-1'}> Select procedure type</option>
                                        {procedureType.map(function (item, i) {
                                            return (<option id={"procedure-type-" + (i + 1)} value={item._id}> {item.type_name} </option>)
                                        })}
                                    </select>
                                    <i class="fa fa-angle-down procedure-type" aria-hidden="true"></i>
                                </div>
                                <div class="form-group">
                                    <label for="formGroupExampleInput">Conditions for 'Suspected High risk of Bleeding?'</label>
                                    {conditions.map((condition, idx) => {
                                        return (<div className="feedback-conditions">{idx !== 0 && <span onClick={() => handleRemove(idx)} className="add-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></span>}<input type="text" class="form-control" value={condition} onChange={(e) => handleValueChange(e, idx)} id="formGroupExampleInput" placeholder="Enter Conditions" /></div>);
                                    })}
                                </div>
                                <p className="text-right"><a className="condition-add" onClick={() => handleAdd()}>+ Add More Condition</a></p>
                            </div>
                            <p className="text-center add-drug-submit"><a onClick={() => editProcedure()}>SUBMIT</a></p>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpenviewfeedback}
                onAfterOpen={afterOpenModalviewfeedback}
                onRequestClose={closeModalviewfeedback}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="edit-modal view-procedure-modal">
                    <div class="modal-header">
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>View Procedure</h2>
                        <button className="cross-btn" onClick={closeModalviewfeedback}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    {singleProcedure &&
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <p>Procedure Name</p>
                                    <h6>{singleProcedure.procedure_name}</h6>
                                </div>
                                <div className="col-6 mb-4">
                                    <p>Added On</p>
                                    <h6>{moment(singleProcedure.createdAt).format("DD MMMM, YYYY")}</h6>
                                </div>
                                <div className="col-6 mb-4">
                                    <p>Status</p>
                                    {/* <h6>{singleProcedure.status == 'ACT' ? "Active" : "Disable"}</h6> */}
                                    <h6 class={isProcedureDisable ? "active-status" : 'deactive-status'}>{isProcedureDisable ? "Enable" : "Disable"}</h6>
                                </div>
                                <div className="col-12 mb-4">
                                    <p>Procedure Type</p>
                                    <h6>{singleProcedure.procedure_type.type_name}</h6>
                                </div>

                            </div>
                            <p>Conditions for 'Suspected High risk of Bleeding?'</p>
                            <ul className="suspect-high">
                                {singleProcedure.procedure_conditions_for_high_risk_of_bleeding.map(function (tag, i) {
                                    return <li><span>{tag}</span></li>
                                })}
                            </ul>
                        </div>}
                    <div className="row disable-edit-btn text-center d-flex justify-content-center">
                        <div className="col-sm-5">
                            <a onClick={() => handleStatus(singleProcedure._id, singleProcedure.status)}>{isProcedureDisable ? "DISABLE" : 'ENABLE'} PROCEDURE</a>
                        </div>
                        <div className="col-sm-5 edit-drug-btn">
                            <a onClick={() => openModalEditdrug(singleProcedure)}>EDIT PROCEDURE</a>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpendelete}
                onAfterOpen={afterOpenModalDelete}
                onRequestClose={closeModaldelete}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="delete-modal">
                    <div className="modal-header" >
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Delete Procedure</h2>
                        <button className="cross-btn" onClick={closeModaldelete}><img className="cross-image" src="./images/cross.png" /></button>
                    </div>
                    <div class="modal-body add-catergory-form">
                        <div className="col-md-12">
                            <div className="form-group">
                                <p>Are you sure you want to delete "{deletedData.procedure_name}" ?</p>

                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="signup-button text-center mb-3">
                                
                                    <button className="btn btn-primary mr-2" onClick={() => deleteProcedure(deletedData._id)}>Yes</button>
                                

                                <button className="btn btn-secondary no_delete" onClick={() => closeModaldelete()}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Procedure