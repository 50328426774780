import React from 'react';

// import footer_logo from '../../assets/images/footer_logo.png'

const Footer = () => {
    
    return (
        <footer className="footer">
        <p className="text-center footer-text">&copy; Copyright 2021, All Rights Reserved. </p>
    </footer>
    )
}

export default Footer