
import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Sidebar from '../Component/Sidebar';
import { userService } from '../_services';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';
import { Document, Page, pdfjs } from 'react-pdf';
import { config } from '../config/config'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import Loader from '../Component/Loader'
import Paginate from '../Component/Pagination';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


const Checklist = (props) => {
  const [checkLists, setChecklists] = useState([]);
  const [singleCheckList, setSingleCheckList] = useState('');
  const [modalIsOpendelete, setIsOpenedelete] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [checklist, setCheckList] = useState('');
  const [checkListPdf, setCheckListPdf] = useState('');
  const timezone = jstz.determine();
  const tz = timezone.name()
  const [checkListUrl, setCheckListUrl] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [size, setSize] = useState(10);

  useEffect(() => {
    getCheckList(0, size);
  }, []);

  const getCheckList = (page, size) => {
    setisLoading(true);
    userService.getCheckList(page, size).then(function (response) {
      setChecklists(response.data.check_list);
      setisLoading(false);
      setPageCount(response.data.result_count);

    }).catch(function (error) {
      userService.handleError(error);
      setChecklists([]);
      setisLoading(false);
      // toast.error(error.response.data.message);
    });
  }
  const handlePageChange = (page) => {
    console.log(pageCount);
    setPage(page);
    getCheckList(page, size);
  };
  var subtitle;
  const [modalIsOpencvs, setIsOpencvs] = useState(false);
  function openModalcvs() {
    setIsOpencvs(true);
  }

  function afterOpenModalcvs() {
    subtitle.style.color = '#f00';
  }

  function closeModalcvs() {
    setCheckList('')
    setCheckListUrl('')
    setIsOpencvs(false);
  }

  var subtitle;
  const [modalIsOpenviewfeedback, setIsOpenviewfeedback] = useState(false);
  function openModalviewfeedback(data) {
    setCheckListPdf(config.imgBaseUrl + data.checklist_file)
    setSingleCheckList(data);
    setIsOpenviewfeedback(true);
  }

  function afterOpenModalviewfeedback() {
    subtitle.style.color = '#f00';
  }

  function closeModalviewfeedback() {
    setIsOpenviewfeedback(false);
  }

  const [modalIsOpenEditCsv, setModalIsOpenEditCsv] = React.useState(false);
  function openModalEditCsv(data) {
    setSingleCheckList(data)
    setCheckList('');
    setModalIsOpenEditCsv(true);
  }

  function afterOpenModalEditCsv() {
    setCheckList('');
    subtitle.style.color = '#f00';
  }

  function closeModalEditCsv() {
    setCheckListUrl('');
    setModalIsOpenEditCsv(false);
  }

  const [deletedData, setDeletedData] = useState('');
  function closeModaldelete() {
    setIsOpenedelete(false);
  }

  function afterOpenModalDelete() {
    subtitle.style.color = '#f00';
  }

  function openModaldelete(data, index) {
    setDeletedData(data);
    setIsOpenedelete(true);
  }

  function deleteGuideline() {
    setisLoading(true);
    userService.deleteCheckList(deletedData._id).then(function (response) {
      closeModaldelete();
      setisLoading(false);
      toast.success(response.data.message)
      getCheckList(0, size);
    }).catch(function (error) {
      userService.handleError(error);
      // toast.error(error.response.data.message);
      setisLoading(false);
    });
  }

  function handleCheckListChange(e) {
    if (e.target.files[0]) {
      setCheckList(e.target.files[0])
      setCheckListUrl(URL.createObjectURL(e.target.files[0]))
    }
  }

  const uploadCheckList = () => {
    if (checklist === '') {
      toast.error("Please select checklist pdf");
    } else {
      let params = {
        'checklist_file': checklist
      };
      var formdata = new FormData();
      formdata.append('checklist_file', checklist);
      setisLoading(true)
      console.log(params)
      userService.uploadCheckList(formdata).then(function (response) {
        // toast.success(response.data.message);
        toast.success('Check list published successfully');
        setisLoading(false);
        closeModalcvs();
        getCheckList(0, size);
        setTimeout(() => {
          // window.location.href = '/setting';
        }, 1000);
      }).catch(function (error) {
        if (error.response) {
          userService.handleError(error);
          // toast.error(error.response.data.message)
        }
        setisLoading(false);
      });
    }
  }

  const updateCheckList = () => {
    if (checklist === '') {
      toast.error("Please select checklist pdf");
    } else {
      let params = {
        'checklist_id': singleCheckList._id,
        'checklist_file': checklist
      };
      var formdata = new FormData();
      formdata.append('checklist_file', checklist);
      formdata.append('checklist_id', singleCheckList._id);
      setisLoading(true);
      console.log(params)
      userService.updateCheckList(formdata).then(function (response) {
        toast.success(response.data.message);
        setisLoading(false);
        closeModalEditCsv();
        getCheckList(0, size);
        setTimeout(() => {
          // window.location.href = '/setting';
        }, 1000);
      }).catch(function (error) {
        if (error.response) {
          userService.handleError(error);
          // toast.error(error.response.data.message)
        }
        setisLoading(false);
      });
    }
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className="Wrapper">
        <Sidebar />
        <div class="main">
          <Header />
          <div className="main-div">
            <div className="categories-heading">
              <div className="container-fluid">
                <h3>View Checklist</h3>
                <p className="text-right"><a onClick={openModalcvs} className="upload-btn"><i class="fa fa-plus mr-2" aria-hidden="true"></i> UPLOAD NEW</a></p>
              </div>
            </div>
            <section class="category-list guide-line-list">
              <div className="container-fluid">
                {checkLists && checkLists.map((checklist, index) => {
                  const dateToFormat = checklist.publish_date;
                  var aa = moment.tz(checklist.publish_date, tz)
                  let fileName = checklist.file_name.split('.');
                  return (
                    <div>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="pdf-image-box">
                            <Document file={config.imgBaseUrl + checklist.checklist_file}>
                              <Page pageNumber={1} />
                            </Document>
                            {/* <img className="pdf-images" src="./images/pdf.png"></img> */}
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="pdf-box-details">
                            <p>File Name</p>
                            <h6>{fileName[0]}</h6>
                            <p className="mt-4">Published On</p>
                            <h6>{moment(checklist.publish_date).format("DD MMMM, YYYY")}</h6>
                            <ul className="three-pdf-btn mt-4">
                              {/* <li className="one-pdf" onClick={() => openModalviewfeedback(checklist)}>VIEW PDF</li> */}
                              <li className="one-pdf" ><a class='pdf' href={config.imgBaseUrl + checklist.checklist_file} target='_blank' rel='noopener noreferrer'>VIEW PDF</a></li>
                              <li className="two-pdf" onClick={() => openModaldelete(checklist, index)}>DELETE PDF</li>
                              <li className="three-pdf" onClick={() => openModalEditCsv(checklist)}>UPDATE PDF</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <p class="border-line"></p>
                    </div>
                  );
                })}
              </div>
            </section>
            <div className="pagination-list-area">
              <div className="pagination">
              </div>
              {!isLoading ? <p>{(page * size) + 1} - {(page * size) + checkLists.length} of {pageCount} Listing</p> : <p></p>}
              <Paginate count={pageCount} activePage={page} handlePageChange={(page) => handlePageChange(page)} perPageEntries={size} />

            </div>

          </div>
          <Footer />
        </div>
      </div>

      <Modal
        isOpen={modalIsOpencvs}
        onAfterOpen={afterOpenModalcvs}
        onRequestClose={closeModalcvs}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="add-modal cvs-modal">
          <div class="modal-header">
            <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Upload New Checklist PDF</h2>
            <button className="cross-btn" onClick={closeModalcvs}><i class="fa fa-times" aria-hidden="true"></i></button>
          </div>
          <div class="modal-body text-center">
            <input className='custom-input' type="file" accept="application/pdf, application/vnd.ms-excel" onChange={(e) => handleCheckListChange(e)} />
            {checkListUrl && <div className='edit-guide'>
              <Document file={checkListUrl}>
                <Page pageNumber={1} />
              </Document>
            </div> ?
              <div className='edit-guide'>
                <Document file={checkListUrl}>
                  <Page pageNumber={1} />
                </Document>
              </div> :

              <img class='dummy' src="./images/plus.png"></img>
            }
            {/* <img src="./images/plus.png"></img> */}
            <p className="m-2">Upload PDF File</p>

          </div>
          <p className="text-center csv-submit"><a className="" onClick={() => uploadCheckList()}>PUBLISH NOW</a></p>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenviewfeedback}
        onAfterOpen={afterOpenModalviewfeedback}
        onRequestClose={closeModalviewfeedback}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="edit-modal">
          <div className="modal-header" >
            <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>View Checklist</h2>
            <button className="cross-btn" onClick={() => closeModalviewfeedback()}><i className="fa fa-times" aria-hidden="true"></i></button>
          </div>
          <div className="modal-body">
            <iframe className='pdf-viewer' src={checkListPdf}></iframe>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenEditCsv}
        onAfterOpen={afterOpenModalEditCsv}
        onRequestClose={closeModalEditCsv}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="edit-modal cvs-modal">
          <div class="modal-header">
            <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Update Checklist PDF</h2>
            <button className="cross-btn" onClick={closeModalEditCsv}><i class="fa fa-times" aria-hidden="true"></i></button>
          </div>
          <div class="modal-body text-center">
            {/* <input className='custom-input' type="file" accept="application/pdf, application/vnd.ms-excel" onChange={(e) => handleImageChange(e)} /> */}
            {/* <img src="./images/plus.png"></img> */}
            <div className='edit-guide'>
              <Document file={checkListUrl ? checkListUrl : config.imgBaseUrl + singleCheckList.checklist_file}>
                <Page pageNumber={1} />
              </Document>
            </div>
            <input className='custom-input-2' type="file" accept="application/pdf, application/vnd.ms-excel" onChange={(e) => handleCheckListChange(e)} />
            <p className="m-2">Update PDF File</p>
          </div>
          <p className="text-center csv-submit"><a style={{ color: 'white' }} className="" onClick={() => updateCheckList()}>Update</a></p>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpendelete}
        onAfterOpen={afterOpenModalDelete}
        onRequestClose={closeModaldelete}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="delete-modal">
          <div className="modal-header" >
            <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Delete Guideline</h2>
            <button className="cross-btn" onClick={closeModaldelete}><img className="cross-image" src="./images/cross.png" /></button>
          </div>
          <div class="modal-body add-catergory-form">
            <div className="col-md-12">
              <div className="form-group">
                <p>Are you sure you want to delete "{deletedData.file_name}" ?</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="signup-button text-center mb-3">
                <button className="btn btn-primary mr-2" onClick={() => deleteGuideline(deletedData._id)}>Yes</button>
                <button className="btn btn-secondary no_delete" onClick={() => closeModaldelete()}>No</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Checklist