import { authHeader } from '../_helpers';
import { config } from '../config/config'
import axios from 'axios';
import { toast } from 'react-toastify';
export const userService = {
  login,
  changePassword,
  getDrugs,
  enableDisableDrug,
  addDrug,
  feedbackList,
  replyToFeedback,
  procedureList,
  addProcedure,
  procedureTypeList,
  editProcedure,
  editDrug,
  enableDisableProcedure,
  uploadProfileImage,
  getUsersList,
  uploadGuideline,
  getGuidelineList,
  deleteGuidelineList,
  updateGuideline,
  enableDisableUser,
  getCheckList,
  uploadCheckList,
  deleteCheckList,
  updateCheckList,
  handleError,
  uploadDrugCsv,
  trackUser,
  uploadDrugCsvByLakshay
};

const configheaders = {
  headers: authHeader()
}

function login(params) {
  let url = `${config.baseurl}/users/login`;
  return axios.post(url, params, configheaders);
}
function changePassword(params) {
  let url = `${config.baseurl}/users/changepassword`;
  return axios.post(url, params, configheaders);
}
function getDrugs(search, pageNo, size) {
  let url = `${config.baseurl}/admin/get_drug_list?search=${search}&pageNo=${pageNo}&size=${size}`;
  return axios.get(url, configheaders);
}
function enableDisableDrug(params) {
  let url = `${config.baseurl}/admin/enable_disable_drug`;
  return axios.post(url, params, configheaders);
}
function addDrug(params) {
  let url = `${config.baseurl}/drugs`;
  return axios.post(url, params, configheaders);
}
function feedbackList(search, pageNo, size) {
  let url = `${config.baseurl}/admin/get_feedbacks_list?search=${search}&pageNo=${pageNo}&size=${size}`;
  return axios.get(url, configheaders);
}
function replyToFeedback(params) {
  let url = `${config.baseurl}/admin/reply_to_user_feedback`;
  return axios.post(url, params, configheaders);
}
function procedureList(search, pageNo, size) {
  let url = `${config.baseurl}/admin/get_procedure_list?search=${search}&pageNo=${pageNo}&size=${size}`;
  return axios.get(url, configheaders);
}
function addProcedure(params) {
  let url = `${config.baseurl}/procedure`;
  return axios.post(url, params, configheaders);
}
function procedureTypeList() {
  let url = `${config.baseurl}/procedure/get_procedure_type_list`;
  return axios.get(url, configheaders);
}
function editProcedure(params) {
  let url = `${config.baseurl}/procedure`;
  return axios.put(url, params, configheaders);
}
function editDrug(params) {
  let url = `${config.baseurl}/drugs`;
  return axios.put(url, params, configheaders);
}
function enableDisableProcedure(params) {
  let url = `${config.baseurl}/admin/enable_disable_procedure`;
  return axios.post(url, params, configheaders);
}
function uploadProfileImage(params) {
  let url = `${config.baseurl}/admin/upload_profile_image`;
  return axios.put(url, params, configheaders);
}
function getUsersList(search, pageNo, size) {
  let url = `${config.baseurl}/admin/get_users_list?search=${search}&pageNo=${pageNo}&size=${size}`;
  return axios.get(url, configheaders);
}
function uploadGuideline(params) {
  let url = `${config.baseurl}/admin/upload_guideline`;
  return axios.post(url, params, configheaders);
}
function getGuidelineList(pageNo, size) {
  let url = `${config.baseurl}/admin/get_guideline_list?pageNo=${pageNo}&size=${size}`;
  return axios.get(url, configheaders);
}
function deleteGuidelineList(id) {
  console.log("configheaders", configheaders);
  let url = `${config.baseurl}/admin/delete_guideline?guideline_id=${id}`;
  return axios.delete(url, configheaders);
}
function updateGuideline(params) {
  let url = `${config.baseurl}/admin/update_guideline`;
  return axios.put(url, params, configheaders);
}
function enableDisableUser(params) {
  let url = `${config.baseurl}/admin/enable_disable_user`;
  return axios.patch(url, params, configheaders);
}
function getCheckList(pageNo, size) {
  let url = `${config.baseurl}/admin/get_list_of_checklist?pageNo=${pageNo}&size=${size}`;
  return axios.get(url, configheaders);
}
function uploadCheckList(params) {
  let url = `${config.baseurl}/admin/upload_checklist`;
  return axios.post(url, params, configheaders);
}
function deleteCheckList(id) {
  console.log("configheaders", configheaders);
  let url = `${config.baseurl}/admin/delete_checklist?checklist_id=${id}`;
  return axios.delete(url, configheaders);
}
function updateCheckList(params) {
  let url = `${config.baseurl}/admin/update_checklist`;
  return axios.put(url, params, configheaders);
}
function uploadDrugCsv(params) {
  let url = `${config.baseurl}/admin/upload_drug_csv`;
  return axios.post(url, params, configheaders);
}
function trackUser(userId,pageNo,size) {
  let url = `${config.baseurl}/trackuser/?user_id=${userId}&pageNo=${pageNo}&size=${size}`;
  return axios.get(url, configheaders);
}








function uploadDrugCsvByLakshay() {
  let url = `http://192.168.2.188:8000/csv_get_db`;
  return axios.get(url);
}






function handleError(error) {
  console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrrr", error);
  // console.log("response",error.response);
  // console.log("response",error.response.status);
  // console.log("response",error.response.data.message);
  if (error.response && error.response.status == 401) {
    toast.error(error.response.data.message);
    console.log("redirectionnnn");
    localStorage.clear();
    window.location.href = "/";
  } else {
    if (error.name == 'NetworkError') {
      toast.error('Please check your network');
    } else if (error.response) {
      toast.error(error.response.data.message);
      // alert(error.response.data.message);
    }
    else {

      toast.error("Something went wrong")
    }
  }
}