
import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Sidebar from '../Component/Sidebar';
import { Link } from "react-router-dom";
import { userService } from '../_services';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';
import Loader from '../Component/Loader'
import ReactPaginate from 'react-paginate';
import Pagination from "react-js-pagination";
import Paginate from '../Component/Pagination';

const Dashboard = (props) => {
    const [users, setUsers] = useState([]);
    const [usersCount, setUsersCount] = useState('');
    const [drugsCount, setDrugsCount] = useState('');
    const [proceduresCount, setProceduresCount] = useState('');
    const [search, setSearch] = useState('');
    const [procedureOfReversal, setProcedureOfReversal] = useState('');
    const timezone = jstz.determine();
    const tz = timezone.name()
    const [isLoading, setisLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [size, setSize] = useState(10);

    useEffect(() => {
        setisLoading(true);
        getAllUsersList('', 0, size);
    }, []);

    const getAllUsersList = (searchTxt, pageNo, pageSize) => {
        // setisLoading(true);
        userService.getUsersList(searchTxt, pageNo, pageSize).then(function (response) {
            setUsers(response.data.users_list);
            setUsersCount(response.data.users_count);
            setDrugsCount(response.data.drugs_count);
            setProceduresCount(response.data.procedures_count);
            setPageCount(response.data.total_result_count);
            setisLoading(false);
        }).catch(function (error) {
            setisLoading(false);
            setUsers([]);
            userService.handleError(error);
            // toast.error(error.response.data.message);
        });
    }
    const handlePageChange = (page) => {
        console.log(pageCount);
        setPage(page);
        // setCurrentPage(size+1);
        getAllUsersList('', page, size);
    };

    //   const handlePageSizeChange = (event) => {
    //     setPageSize(event.target.value);
    //     setPage(1);
    //   };

    function onSearchTextChange(value) {
        setSearch(value);
        setPage(0);
        getAllUsersList(value, 0, size);
    }

    function handleStatus(id, status) {
        setisLoading(true);
        let tmpStatus = status === 'ACT' ? 'DIS' : 'ACT'
        let params = {
            user_id: id,
            status: tmpStatus
        };
        userService.enableDisableUser(params).then(function (response) {
            getAllUsersList('', page, size);
            setisLoading(false);
        }).catch(function (error) {
            setisLoading(false);
            userService.handleError(error);
            // toast.error(error.response.data.message);
        });
    }

    return (
        <div>
            {isLoading && <Loader />}
            <div className="Wrapper">
                <Sidebar />
                <div class="main">
                    <Header />
                    <div className="main-div">
                        <section className="vategory-all_area">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4 dash-byer-main">
                                        <div className="buyers-box">
                                            <div className="media buyer-media">
                                                <img src="./images/user.png" alt="digital" className="img-fluid mr-4" />
                                                <div className="media-body centered">
                                                    <h2 className="mt-0">{usersCount}</h2>
                                                    <p> Users</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 dash-byer-main">
                                        <div className="delivery-box">
                                            <div className="media buyer-media">
                                                <img src="./images/drug.png" alt="digital" className="img-fluid mr-4" />
                                                <div className="media-body">
                                                    <h2 className="mt-0">{drugsCount}</h2>
                                                    <p>Drugs</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 dash-byer-main">
                                        <div className="sellers-box">
                                            <div className="media buyer-media">
                                                <img src="./images/setting.png" alt="digital" className="img-fluid mr-4" />
                                                <div className="media-body">
                                                    <h2 className="mt-0">{proceduresCount}</h2>
                                                    <p>Procedures</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="categories-heading">
                            <div className="container-fluid">
                                <h3>Users</h3>
                                <div class="input-group input-group-md category-search">
                                    <input type="text" class="form-control" aria-label="Large" aria-describedby="inputGroup-sizing-sm" onChange={(e) => onSearchTextChange(e.target.value)} placeholder="Search by name, email..." />
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-lg"><i class="fa fa-search" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section class="category-list">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th className="name-th">Name</th>
                                            <th className="email-th">Email Address</th>
                                            <th className="create-th">Created On</th>
                                            <th className="status-th">Status</th>
                                            <th className="action-th">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users && users.length > 0 ? users.map((user, index) => {
                                            const dateToFormat = user.createdAt;
                                            var aa = moment.tz(user.createdAt, tz)
                                            return (
                                                <tr>
                                                    <td className="text-center">{(page * size) + (index + 1)}</td>
                                                    <td>{user.first_name} {user.last_name}</td>
                                                    <td>{user.email}</td>

                                                    <td>{moment(user.createdAt).format("DD MMMM, YYYY")}</td>
                                                    {/* <td><Moment format="DD-MM-YYYY" withTitle>{aa}</Moment></td> */}
                                                    <td className={user.status === 'ACT' ? "active-status" : 'deactive-status'}>{user.status === 'ACT' ? 'Active ' : 'Suspended'}</td>
                                                    <td><div class="dropdown">
                                                        <button class="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Select <i class="fa fa-angle-down ml-2" aria-hidden="true"></i>
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <a className="dropdown-item four-item" onClick={() => handleStatus(user._id, user.status)}>{user.status == 'ACT' ? "Suspend" : 'Active'} User</a>
                                                            <a className="dropdown-item four-item" href={`/usage_tracking?user_id=${user._id}`}>Usage Tracking</a>
                                                            {/* <a className="dropdown-item four-item" href={`/usage_tracking/${user._id}`}>Usage Tracking</a> */}

                                                        </div>
                                                    </div></td>
                                                </tr>
                                            );
                                        })
                                            :
                                            <tr><td colSpan={6} className="text-center"><b>{search ? "No User found" : "No User added yet"}</b></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </section>
                        {/* <div style={{ width: '100%' }}>
                           <Paginate count={pageCount} activePage={page} handlePageChange={(page) => handlePageChange(page)} perPageEntries={size} />
                           </div> */}



                        <div className="pagination-list-area">
                            {!isLoading ? <p>{(page*size) +1} - { (page*size) + users.length} of {pageCount} Listing</p> : <p></p>}
                            <Paginate count={pageCount} activePage={page} handlePageChange={(page) => handlePageChange(page)} perPageEntries={size} />
                        </div>







                        
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Dashboard