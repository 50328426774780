
import React, { Component, useState, useEffect } from "react";
import Modal from 'react-modal';
import { userService } from '../_services';
import { toast } from 'react-toastify';
import Loader from '../Component/Loader'
const Login = (props) => {
  const [isLoading, setisLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inputType, setInputType] = useState("password");

  const submit = () => {
    var emailRegrex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (email === '') {
      toast.error("Please Enter Email");
    } else if (!emailRegrex.test(email)) {
      console.log('!emailRegrex.test(email)')
      toast.error("Invalid Email");
    } else if (password === '') {
      toast.error("Please Enter Password");
    } else {
      let params = {
        'email': email,
        'password': password,
        'role': 'Admin'
      };
      setisLoading(true);
      console.log(params);
      userService.login(params).then(function (response) {
        setisLoading(true);
        // toast.success(response.data.message);
        toast.success("Login successfully");
        localStorage.setItem("user_id", response.data.user._id);
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem('profile_image', response.data.user.profile_image);
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      }).catch(function (error) {
        if (error.response) {
          // toast.error(error.response.data.message)
          toast.error(" Email or password is incorrect")


        }
        setisLoading(false);
      });
    }
  }

  return (
    <div>
      {isLoading && <Loader />}
      <section className="outer_section main_content">
        <div className="container">
          <div className="inner_section">
            <div className="logo_form text-center">
              <a href="/">
                <img src="./images/logo.png" alt="logo" className="mb-4" />
              </a>
            </div>

            <div className="main_form">
              <h3 className="text-center">LOGIN TO CONTINUE</h3>
              <form>
                <div className="form-group">
                  <label for="formGroupExampleInput">Email Address</label>
                  <input type="text" className="form-control" id="formGroupExampleInput" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email address" />
                </div>
                <i className={"password-eye " + (inputType === "password" ? "fa fa-eye-slash" : "fa fa-eye")} onClick={() => ((inputType === 'text') ? setInputType('password') : setInputType('text'))}></i>
                <div className="form-group">
                  <label for="formGroupExampleInput2">Password</label>
                  <input type={inputType} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} id="formGroupExampleInput2" placeholder="Enter your password" />
                </div>


                <div className="password_icon">
                  {/* <i className= "fa fa-eye-slash"></i> */}
                </div>
                <div className="bottom_links text-center">
                  <a onClick={() => submit()} className="btn login-btn">LOGIN</a>
                </div>
              </form>
            </div>


          </div>

        </div>
      </section>
    </div>
  )
}

export default Login