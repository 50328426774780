
import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Sidebar from '../Component/Sidebar';
import { userService } from '../_services';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';
import Loader from '../Component/Loader'
import Paginate from '../Component/Pagination';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const Feedback = (props) => {
    const [feedback, setFeedback] = useState([]);
    const [singleFeedback, setSingleFeedback] = useState('');
    const [search, setSearch] = useState('');
    const [comment, setComment] = useState({ value: '<p>Hello,<br/>Thank you so much for taking the time to send us your feedback! Our team shall work on improving your experience.<br/><br/>   Best regards,<br/>ASIPP team</p>' });
    const [dateFormet, setDateFormet] = useState('');
    const [timeFormet, setTimeFormet] = useState('');
    const timezone = jstz.determine();
    const tz = timezone.name()
    const [isLoading, setisLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [size, setSize] = useState(10);

    useEffect(() => {
        getFeedbackList('',0,size);
    }, []);

    const getFeedbackList = (searchTxt,page,size) => {
        setisLoading(true);
        userService.feedbackList(searchTxt,page,size).then(function (response) {
            setFeedback(response.data.feedback_list);
            setisLoading(false);
            setPageCount(response.data.result_count);
        }).catch(function (error) {
            setisLoading(false);
            userService.handleError(error);
            setFeedback([]);
            // toast.error(error.response.data.message);
        });
    }
    const handlePageChange = (page) => {
        console.log(pageCount);
        setPage(page);
        getFeedbackList('', page,size);
    };
    function onSearchTextChange(value) {
        setSearch(value);
        getFeedbackList(value,0,size);
        setPage(0);
    }






    
    const submit = () => {
        if (comment.value === '') {
            toast.error("Please enter comment");
        } else {
            let params = {
                'feedback_id': singleFeedback._id,
                'reply_text': comment.value
            };
            setisLoading(true);
            console.log(params);
            userService.replyToFeedback(params).then(function (response) {
                setisLoading(false);
                toast.success(response.data.message);
                setTimeout(() => {
                    window.location.href = '/feedback';
                }, 1000);
            }).catch(function (error) {
                if (error.response) {
                    userService.handleError(error);
                    // toast.error(error.response.data.message)
                }
                setisLoading(false);
            });
        }
    }

    var subtitle;
    const [modalIsOpenedit, setIsOpenedit] = React.useState(false);
    function openModaledit(data) {
        const dateToFormat = data.createdAt;
        setTimeFormet(dateToFormat);
        var aa = moment.tz(data.createdAt, tz)
        setTimeFormet(aa);
        setSingleFeedback(data);
        setIsOpenedit(true);
    }

    function afterOpenModaledit() {
        subtitle.style.color = '#f00';
    }

    function closeModaledit() {
        setIsOpenedit(false);
    }

    var subtitle;
    const [modalIsOpenfeedback, setIsOpenfeedback] = React.useState(false);
    function openModalfeedback() {
        setIsOpenfeedback(true);
        setIsOpenedit(false);
    }

    function afterOpenModalfeedback() {
        subtitle.style.color = '#f00';
    }

    function closeModalfeedback() {
        setIsOpenfeedback(false);
    }

    var subtitle;
    const [modalIsOpenviewfeedback, setIsOpenviewfeedback] = React.useState(false);
    function openModalviewfeedback(data) {
        const dateToFormat = data.createdAt;
        setTimeFormet(dateToFormat);
        var aa = moment.tz(data.createdAt, tz)
        setTimeFormet(aa);
        setSingleFeedback(data);
        setIsOpenviewfeedback(true);
    }

    function afterOpenModalviewfeedback() {
        subtitle.style.color = '#f00';
    }

    function closeModalviewfeedback() {
        setIsOpenviewfeedback(false);
    }

    function handleComment(value){
        setComment({value});
    }

    return (
        <div>
            {isLoading && <Loader />}
            <div className="Wrapper">
                <Sidebar />
                <div class="main">
                    <Header />
                    <div className="main-div">
                        <div className="categories-heading">
                            <div className="container-fluid">
                                <h3>Feedback by users</h3>
                                <div class="input-group input-group-md category-search">
                                    <input type="text" class="form-control" aria-label="Large" aria-describedby="inputGroup-sizing-sm" onChange={(e) => onSearchTextChange(e.target.value)} placeholder="Search by name, email..." />
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-lg"><i class="fa fa-search" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section class="category-list">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th className="name-th">Name</th>
                                            <th className="email-th">Email Address</th>
                                            <th className="submit-th">Submitted On</th>
                                            <th className="comment-th">Comments</th>
                                            <th className="action-th">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {feedback.length > 0 ? feedback.map((data, index) => {
                                            const dateToFormat = data.createdAt;
                                            var aa = moment.tz(data.createdAt, tz)
                                            return (
                                                <tr>
                                                    <td className="text-center">{(page * size) + (index + 1)}</td>
                                                    <td>{data.replied_data && <p className="replied">Replied</p>}{data.full_name}</td>
                                                    <td>{data.email_address}</td>
                                                    <td>{moment(data.createdAt).format("DD MMMM, YYYY")}</td>
                                                    <td>{data.user_comment_for_feedback}</td>
                                                    <td><div class="dropdown">
                                                        <button class="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Select <i class="fa fa-angle-down ml-2" aria-hidden="true"></i>
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item four-item" onClick={() => openModalviewfeedback(data)}>View Feedback</a>
                                                            {!data.replied_data && data.need_to_respond && <a class="dropdown-item" onClick={() => openModaledit(data)}>Reply to Feedback</a>}
                                                        </div>
                                                    </div></td>
                                                </tr>
                                            );
                                        })
                                            :
                                            <tr><td colSpan={6} className="text-center"><b>{search ? "No Drugs found" : "No Feedback added yet"}</b></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </section>
                        <div className="pagination-list-area">
                            <div className="pagination">
                            </div>
                            {!isLoading ? <p>{(page*size) +1} - { (page*size) + feedback.length} of {pageCount} Listing</p> : <p></p>}
                            <div className="pagination">
                            </div>
                            <Paginate count={pageCount} activePage={page} handlePageChange={(page) => handlePageChange(page)} perPageEntries={size} />

                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

            <Modal
                isOpen={modalIsOpenedit}
                onAfterOpen={afterOpenModaledit}
                onRequestClose={closeModaledit}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="edit-modal" id="view-feedback">
                    <div class="modal-header">
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>View Feedback</h2>
                        <button className="cross-btn" onClick={closeModaledit}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div class="modal-body">
                        <div className="row">
                            <div className="col-6 mb-4">
                                <p>Full Name</p>
                                <h6>{singleFeedback.full_name}</h6>
                            </div>
                            <div className="col-6 mb-4">
                                <p>Email Address</p>
                                <h6>{singleFeedback.email_address}</h6>
                            </div>
                            <div className="col-6 mb-4">
                                <p>Submission Type</p>
                                <h6>{singleFeedback.submission_type}</h6>
                            </div>
                            <div className="col-6 mb-4">
                                <p>Would you like us to respond?</p>
                                <h6>{singleFeedback.need_to_respond == true ? 'Yes' : 'No'}</h6>
                            </div>
                            <div className="col-6 mb-4">
                                <p>Submitted On</p>
                                <h6>{moment(singleFeedback.createdAt).format("DD MMMM, YYYY")}</h6>
                            </div>
                        </div>
                        <p>Comments</p>
                        {/* <h6>{singleFeedback.user_comment_for_feedback}</h6> */}
                        <ReactQuill
                            theme="snow"
                            value={singleFeedback.user_comment_for_feedback}
                            readOnly={true}
                        />
                        {/* <p>Comments</p>
                        <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                        has been the industry's standard dummy text ever since the 1500s, when an unknown
                        printer took a galley of type and scrambled it to make a type specimen book. It has survived
                                not only five centuries</h6> */}
                        <h5 className="text-center feedback-reply-bt"><a onClick={() => openModalfeedback()}>REPLY TO FEEDBACK</a></h5>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpenfeedback}
                onAfterOpen={afterOpenModalfeedback}
                onRequestClose={closeModalfeedback}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="feedback-reply-modal">
                    <div class="modal-header">
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Reply to Feedback</h2>
                        <button className="cross-btn" onClick={closeModalfeedback}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="formGroupExampleInput">Your Comments</label>
                            <ReactQuill
                                theme="snow"
                                value={comment.value}
                                onChange={handleComment}
                                placeholder={"Enter Comment Here..."}
                                // modules={modules}
                                // formats={formats}
                            />
                            {/* <textarea class="form-control" id="exampleFormControlTextarea1" value={comment} onChange={e => setComment(e.target.value)} placeholder="Enter your comments" rows="10"></textarea> */}
                        </div>
                        <h5 className="text-center feedback-submit-bt"><a onClick={() => submit()}>SUBMIT</a></h5>
                        <br/>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpenviewfeedback}
                onAfterOpen={afterOpenModalviewfeedback}
                onRequestClose={closeModalviewfeedback}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="edit-modal" id="feedback-modal">
                    <div class="modal-header">
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>View Feedback</h2>
                        <button className="cross-btn" onClick={closeModalviewfeedback}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div class="modal-body" >
                        <div className="row">
                            <div className="col-6 mb-4">
                                <p>Full Name</p>
                                <h6>{singleFeedback.full_name}</h6>
                            </div>
                            <div className="col-6 mb-4">
                                <p>Email Address</p>
                                <h6>{singleFeedback.email_address}</h6>
                            </div>
                            <div className="col-6 mb-4">
                                <p>Submission Type</p>
                                <h6>{singleFeedback.submission_type}</h6>
                            </div>
                            <div className="col-6 mb-4">
                                <p>Would you like us to respond?</p>
                                <h6>{singleFeedback.need_to_respond == true ? 'Yes' : 'No'}</h6>
                            </div>
                            <div className="col-6 mb-4">
                                <p>Submitted On</p>
                                <h6>{moment(singleFeedback.createdAt).format("DD MMMM, YYYY")}</h6>
                            </div>
                        </div>
                        <p>Comments</p>
                        {/* <h6>{singleFeedback.user_comment_for_feedback}</h6> */}
                        <ReactQuill
                            theme="snow"
                            value={singleFeedback.user_comment_for_feedback}
                            readOnly={true}
                        />
                        
                    </div>
                    {singleFeedback.replied_data && <div className="your-reply-text">
                        <h5>You replied to this feedback</h5>
                        <p>Submitted On</p>
                        <h6>{moment(singleFeedback.createdAt).format("DD MMMM, YYYY")}</h6><br />
                        {/* <h6>{singleFeedback.replied_data}</h6> */}
                        <ReactQuill
                            theme="snow"
                            value={singleFeedback.replied_data}
                            readOnly={true}
                        />
                    </div>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default Feedback