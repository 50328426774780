import React, { useState,useEffect} from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';   
import { Link } from "react-router-dom"; 
import $ from 'jquery';
import { config } from '../config/config'


function Opennav(){
   $('.sidebar').toggle();
}


const Header = (props) =>{
   const [profilePicUrl, setProfilePicUrl] = useState('');

   useEffect(() => {
      let img = localStorage.getItem('profile_image');
      if (img) setProfilePicUrl(config.imgBaseUrl + img);

  }, []);
   $(function(){
      var shrinkHeader = 50;
       $(window).scroll(function() {
         var scroll = getCurrentScroll();
          if ( scroll >= shrinkHeader ) {
              $('.header').addClass('shrink');
            }
            else {
               $('.header').removeClass('shrink');
            }
       });
      function getCurrentScroll() {
         return window.pageYOffset || document.documentElement.scrollTop;
      }
        
   });
  
   function getHeaderName(){
      let pathname = window.location.pathname;
      if (pathname === '/'){
         return 'Dashboard';
      }else if (pathname === '/drug'){
         return 'Pain Guidelines';
      }else if (pathname === '/procedure'){
         return 'Pain Guidelines';
      }else if (pathname === '/guidelines'){
         return 'Pain Guidelines';
      }else if (pathname === '/checklist'){
         return 'Pain Guidelines';
      }else if (pathname === '/feedback'){
         return 'Feedback';
      }else if (pathname === '/setting'){
         return 'Settings';
      }else if (pathname === '/usage_tracking'){
         return 'Usage Tracking';
      }else {
         return '';
      }
   }

  return(
    <div>
       
    <header className="header">
         <div className="container-fluid">
            <div className="profile-ez-area">
               <div className="main-logo-box-area">
               {/* <img src={profilePicUrl} className="responsive-logo mr-2" alt="logo" width="200" /> */}

                  {/* <Link to="/home"><img src={require("../../images/logo_new.png")} className="responsive-logo-final" alt="logo" width="50" /> </Link> */}
                  {/* <img src="./images/profile_upload.png" className="responsive-logo mr-2" alt="logo" width="200" /> */}
                  <p className="header-dash-text"><img src="./images/menu.png" className="text-white menubar" onClick={Opennav}/>  <span
                     className="dashboard-title-hide">{getHeaderName()}</span></p>


               </div>
               <div className="dropdown main-top-area">
                  <img style={{ marginRight: 10 }} src={(props.profileUrl ? props.profileUrl : profilePicUrl) ? (props.profileUrl ? props.profileUrl : profilePicUrl) : './images/user.png'} alt="logo" width= '50'/>
                  {/* <img style={{ marginRight: 10 }} src="./images/bea.png" alt="logo" /> */}
               </div>
            </div>
         </div>
      </header>
      

    </div>
)
}

export default Header

