export function authHeader() {
 
    // return authorization header with basic auth credentials
    let user = localStorage.getItem('access_token')

    if (user && user) {
        return { 'authorization':user, appVersion:0, deviceType:"web", 'Content-Type': 'application/json',"Access-Control-Allow-Origin": '*', 'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone};
    } else {
        return {appVersion:0,deviceType:"web","Access-Control-Allow-Origin": '*' ,'Content-Type': 'application/json',};
    }
}
