
import React, { useEffect, useState } from "react";
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Sidebar from '../Component/Sidebar';
import Loader from '../Component/Loader'
import { userService } from '../_services';
import Moment from 'react-moment';
import * as moment from 'moment-timezone';
import Paginate from '../Component/Pagination';

const Usage_tracking = (props) => {
    const [isLoading, setisLoading] = useState(false);
    const [trackUserData, setTrackUserData] = useState([]);
    const [totalSearches, setTotalSearches] = useState('');
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [size, setSize] = useState(6);

    const user_id = new URLSearchParams(window.location.search)?.get("user_id");
    if(user_id){
        localStorage.setItem('trackId',user_id)
    }
   
    // const user_id = props.match.params.id;


    useEffect(() => {
        getUserTrackData(0,size);
        // debugger
        console.log(props.match.params.id);
    }, []);

    const getUserTrackData = ( pageNo, size) => {
        setisLoading(true);
        userService.trackUser(localStorage.getItem('trackId'),pageNo, size).then(function (response) {
            setTrackUserData(response.data.data);
            setTotalSearches(response.data.totalCountOfSearches);
            setPageCount(response.data.paginationSearchCount);
            setisLoading(false);
        }).catch(function (error) {
            setisLoading(false);
            userService.handleError(error);
            // toast.error(error.response.data.message);
        });
    }

    const handlePageChange = (page) => {
        console.log(pageCount);
        setPage(page);
        getUserTrackData( page, size);
    };
    return (
        <div>
            {isLoading && <Loader />}
            <div className="Wrapper">
                <Sidebar />
                <div class="main">
                    <Header />
                    <div className="main-div">
                        <section className="overall-drug-page">
                            <div className="container-fluid">
                                <div className="media-body centered">
                                    <h2 className="mt-0">Overall Drug/Procedure <span className="searches">{totalSearches ? ('Searches= ' + totalSearches) : ''} </span></h2>
                                </div>
                                {trackUserData.length > 0 ? trackUserData.map((trackData, index) => {
                                    return (
                                        <div className="dates-area">
                                            <h3><span className="dot-box"></span> Date - {moment(trackData.createdAt).format("DD/MMMM/YYYY")}</h3>
                                            <div className="row mt-3">
                                                <div className="col-md-6">
                                                    <div className="drug-name-box">
                                                        <p>{trackData.drug.drug_name}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="drug-name-box">
                                                        <p>{trackData.procedure.procedure_name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                                    :
                                    <tr><td colSpan={6} className="text-center"><b>{"Tracking not available for selected user"}</b></td></tr>
                                }
                            </div>
                        </section>
                        
                    </div>
                    <div className="pagination-list-area">
                            <div className="pagination">
                            </div>
                            {!isLoading ? <p>{(page * size) + 1} - {(page * size) + trackUserData.length} of {pageCount} Listing</p> : <p></p>}
                            <div className="pagination">
                            </div>
                            <Paginate count={pageCount} activePage={page} handlePageChange={(page) => handlePageChange(page)} perPageEntries={size} />
                        </div>
                </div>
            </div>

        </div>


    )
}
export default Usage_tracking