
import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Sidebar from '../Component/Sidebar';
import { userService } from '../_services';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';
import { Document, Page, pdfjs } from 'react-pdf';
import { config } from '../config/config'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import Loader from '../Component/Loader'
import Paginate from '../Component/Pagination';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const Guidelines = (props) => {
    const [guidelines, setGuidelines] = useState([]);
    const [singleGuideline, setSingleGuideline] = useState('');
    const [modalIsOpendelete, setIsOpenedelete] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [guideline, setGuideline] = useState('');
    const [guidelinePdf, setGuidelinePdf] = useState('');
    const timezone = jstz.determine();
    const tz = timezone.name()
    const [guidelineUrl, setGuidelineUrl] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const thumbnailPluginInstance = thumbnailPlugin();
    const { Thumbnails } = thumbnailPluginInstance;
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [size, setSize] = useState(10);

    useEffect(() => {
        getGuideLineList(0, size);
    }, []);

    const getGuideLineList = (page, size) => {
        setisLoading(true);
        userService.getGuidelineList(page, size).then(function (response) {
            setGuidelines(response.data.full_guideline_list);
            setisLoading(false);
            setPageCount(response.data.result_count);

        }).catch(function (error) {
            setisLoading(false);
            userService.handleError(error);
            guidelines([]);
            // toast.error(error.response.data.message);
        });
    }
    const handlePageChange = (page) => {
        console.log(pageCount);
        setPage(page);
        getGuideLineList(page, size);
    };

    var subtitle;
    const [modalIsOpencvs, setIsOpencvs] = useState(false);
    function openModalcvs() {
        setIsOpencvs(true);

    }

    function afterOpenModalcvs() {
        subtitle.style.color = '#f00';
        setGuideline('');
    }

    function closeModalcvs() {
        setGuidelineUrl('');
        setIsOpencvs(false);
    }
    const [modalIsOpenEditCsv, setModalIsOpenEditCsv] = useState(false);
    function openModalEditCsv(data) {
        setSingleGuideline(data)
        if (modalIsOpenEditCsv === false) {
            setGuideline('');
        }
        setModalIsOpenEditCsv(true);
        console.log(modalIsOpenEditCsv);
    }

    function afterOpenModalEditCsv() {
        setGuideline('');
        subtitle.style.color = '#f00';
    }

    function closeModalEditCsv() {
        setGuideline('');
        setGuidelineUrl('')
        setModalIsOpenEditCsv(false);
    }
    const [deletedData, setDeletedData] = useState('');
    function closeModaldelete() {
        setIsOpenedelete(false);
    }
    function afterOpenModalDelete() {

        subtitle.style.color = '#f00';
    }
    function openModaldelete(data, index) {
        setDeletedData(data);
        setIsOpenedelete(true);
    }
    function deleteGuideline() {
        setisLoading(true);
        userService.deleteGuidelineList(deletedData._id).then(function (response) {
            closeModaldelete();
            setisLoading(false);
            toast.success(response.data.message)
            getGuideLineList(0, size);
        }).catch(function (error) {
            userService.handleError(error);
            // toast.error(error.response.data.message);
        });
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    var subtitle;
    const [modalIsOpenviewfeedback, setIsOpenviewfeedback] = useState(false);
    function openModalviewfeedback(data) {
        setGuidelinePdf(config.imgBaseUrl + data.guideline_file)
        setSingleGuideline(data);
        setIsOpenviewfeedback(true);
    }

    function afterOpenModalviewfeedback() {
        subtitle.style.color = '#f00';
    }

    function closeModalviewfeedback() {
        setIsOpenviewfeedback(false);
    }

    function handleGuidelineChange(e) {
        if (e.target.files[0]) {
            setGuideline(e.target.files[0])
            setGuidelineUrl(URL.createObjectURL(e.target.files[0]))
        }
    }
    const uploadGuideline = () => {
        if (guideline === '') {
            toast.error("Please select guideline pdf");
        } else {
            let params = {
                'full_guideline_file': guideline
            };
            var formdata = new FormData();
            formdata.append('full_guideline_file', guideline);
            setisLoading(true)
            console.log(params)
            userService.uploadGuideline(formdata).then(function (response) {
                // toast.success(response.data.message);
                toast.success('Guideline published successfully');
                setisLoading(false);
                closeModalcvs();
                getGuideLineList(0, size);
                setTimeout(() => {
                    // window.location.href = '/setting';
                }, 1000);
            }).catch(function (error) {
                if (error.response) {
                    userService.handleError(error);
                    // toast.error(error.response.data.message)
                }
                setisLoading(false);
            });
        }
    }
    const updateGuideline = () => {
        if (guideline === '') {
            toast.error("Please select guideline pdf");
        } else {
            let params = {
                'guideline_id': singleGuideline._id,
                'full_guideline_file': guideline
            };
            var formdata = new FormData();
            formdata.append('full_guideline_file', guideline);
            formdata.append('guideline_id', singleGuideline._id);

            setisLoading(true)
            console.log(params)
            userService.updateGuideline(formdata).then(function (response) {
                toast.success(response.data.message);
                setisLoading(false);
                closeModalEditCsv();
                getGuideLineList(0, size);
            }).catch(function (error) {
                if (error.response) {
                    userService.handleError(error);
                    // toast.error(error.response.data.message)
                }
                setisLoading(false);
            });
        }
    }

    return (
        <div>
            {isLoading && <Loader />}
            <div className="Wrapper">
                <Sidebar />
                <div class="main">

                    <Header />
                    <div className="main-div">
                        <div className="categories-heading">
                            <div className="container-fluid">
                                <h3>View Full Guidelines</h3>
                                <p className="text-right"><a onClick={() => openModalcvs()} className="upload-btn"><i class="fa fa-plus mr-2" aria-hidden="true"></i>UPLOAD NEW</a></p>
                            </div>
                        </div>
                        <section class="category-list guide-line-list">
                            <div className="container-fluid">
                                {guidelines && guidelines.map((guideline, index) => {
                                    let fileName = guideline.file_name.split('.');
                                    var aa = moment.tz(guideline.publish_date, tz)

                                    return (
                                        <div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="pdf-image-box">
                                                        <Document file={config.imgBaseUrl + guideline.guideline_file}>
                                                            <Page pageNumber={1} />
                                                        </Document>
                                                        {/* <img  className="pdf-images" src="./images/pdf.png"></img> */}
                                                        {/* <img data-pdf-thumbnail-file={config.imgBaseUrl + guideline.guideline_file} className="pdf-images" ></img> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="pdf-box-details">
                                                        <p>File Name</p>
                                                        <h6>{fileName[0]}</h6>
                                                        <p className="mt-4">Published On</p>
                                                        <h6>{moment(guideline.publish_date).format("DD MMMM, YYYY")}</h6>
                                                        <ul className="three-pdf-btn mt-4">
                                                            {/* <li className="one-pdf" onClick={() => openModalviewfeedback(guideline)}>VIEW PDF</li> */}
                                                            <li href={config.imgBaseUrl + guideline.guideline_file} className="one-pdf" ><a class='pdf' href={config.imgBaseUrl + guideline.guideline_file} target='_blank' rel='noopener noreferrer'>VIEW PDF</a></li>
                                                            <li className="two-pdf" onClick={() => openModaldelete(guideline, index)}>DELETE PDF</li>
                                                            <li className="three-pdf" onClick={() => openModalEditCsv(guideline)}>UPDATE PDF</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="border-line"></p>
                                        </div>
                                    );
                                })}
                                {/* <p class="border-line"></p> */}
                            </div>
                        </section>
                        <div className="pagination-list-area">
                            {!isLoading ? <p>{(page*size) +1} - { (page*size) + guidelines.length} of {pageCount} Listing</p> : <p></p>}
                            <Paginate count={pageCount} activePage={page} handlePageChange={(page) => handlePageChange(page)} perPageEntries={size} />

                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <Modal
                isOpen={modalIsOpencvs}
                onAfterOpen={afterOpenModalcvs}
                onRequestClose={closeModalcvs}
                style={customStyles}
                contentLabel="Example Modal">
                {/* <div className="edit-modal new-guideline-modal"> */}
                <div className="add-modal cvs-modal ">
                    <div class="modal-header">
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Upload New Guideline PDF</h2>
                        <button className="cross-btn" onClick={closeModalcvs}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div class="modal-body text-center">
                        <input className='custom-input' type="file" accept="application/pdf, application/vnd.ms-excel" onChange={(e) => handleGuidelineChange(e)} />
                        {guidelineUrl ?
                            <div className='edit-guide'>
                                <Document file={guidelineUrl}>
                                    <Page pageNumber={1} />
                                </Document>
                            </div> :

                            <img className='dummy' src="./images/plus.png"></img>
                        }
                        <p className="m-2">Upload PDF File</p>

                    </div>
                    <p className="text-center csv-submit"><a className="" onClick={() => uploadGuideline()}>PUBLISH NOW</a></p>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpenEditCsv}
                onAfterOpen={afterOpenModalEditCsv}
                onRequestClose={closeModalEditCsv}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="edit-modal cvs-modal">
                    <div class="modal-header">
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Update Guideline PDF</h2>
                        <button className="cross-btn" onClick={() => closeModalEditCsv()} aria-hidden={false}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div class="modal-body text-center">
                        {/* <input className='custom-input' type="file" accept="application/pdf, application/vnd.ms-excel" onChange={(e) => handleGuidelineChange(e)} /> */}
                        {/* <img src="./images/plus.png"></img> */}
                        <div className='edit-guide'>
                            <Document file={guidelineUrl ? guidelineUrl : config.imgBaseUrl + singleGuideline.guideline_file}>
                                <Page pageNumber={1} />
                            </Document>
                        </div>

                        <input className='custom-input-2' type="file" accept="application/pdf, application/vnd.ms-excel" onChange={(e) => handleGuidelineChange(e)} />
                        <p className="m-2">Update PDF File</p>

                    </div>
                    <p className="text-center csv-submit"><a style={{ color: 'white' }} className="" onClick={() => updateGuideline()}>Update</a></p>
                </div>
            </Modal>
            <Modal
                isOpen={modalIsOpendelete}
                onAfterOpen={afterOpenModalDelete}
                onRequestClose={closeModaldelete}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="delete-modal">
                    <div className="modal-header" >
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Delete Guideline</h2>
                        <button className="cross-btn" onClick={closeModaldelete}><img className="cross-image" src="./images/cross.png" /></button>
                    </div>
                    <div class="modal-body add-catergory-form">

                        <div className="col-md-12">
                            <div className="form-group">
                                <p>Are you sure you want to delete <b>{deletedData.file_name}</b> ?</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="signup-button text-center mb-3">
                                <button className="btn btn-primary mr-2" onClick={() => deleteGuideline(deletedData._id)}>Yes</button>
                                <button className="btn btn-secondary no_delete" onClick={() => closeModaldelete()}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={modalIsOpenviewfeedback}
                onAfterOpen={afterOpenModalviewfeedback}
                onRequestClose={closeModalviewfeedback}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="edit-modal">
                    <div className="modal-header" >
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>View Guideline</h2>
                        <button className="cross-btn" onClick={() => closeModalviewfeedback()}><i className="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div className="modal-body">
                        {/* {guidelinePdf} */}
                        {/* <Document
                            file={guidelinePdf}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                            <Page pageNumber={pageNumber} />
                        </Document> */}
                        <iframe className='pdf-viewer' src={guidelinePdf}></iframe>
                        {/* <p>Page {pageNumber} of {numPages}</p>
                        <div>
                            <p>
                                Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                            </p>
                            <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                Previous
                            </button>
                            <button
                                type="button"
                                disabled={pageNumber >= numPages}
                                onClick={nextPage}
                            >
                                Next
                            </button> */}
                        {/* </div> */}
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default Guidelines