const Loader = (props) => {
        return (
            <div className="loaderbox loginloader">
                <div>
                    <div style={{marginLeft: '23px', marginBottom: '10px' }}><div className="loader"></div></div>
                    <p style={{color: '#6c718c', fontSize: '18px'}}>Please Wait...</p>
                </div>
            </div>
        )
}

export default Loader;