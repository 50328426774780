
import React, { Component, useEffect } from "react";
import Modal from 'react-modal';
import Header from '../Component/Header';
import { Link } from "react-router-dom";

const Sidebar = (props) => {
	var currentPath = window.location.pathname;
	const logout = () => {
		localStorage.clear('access_token');
		window.location.href = '/'
	}

	useEffect(() => {
		if (currentPath !== '/') {
			let id = localStorage.getItem('active_list_id');
			if (id) document.getElementById(id).classList.add("show");
		}
	}, []);

	function setActiveId(id) {
		localStorage.setItem('active_list_id', id === 'dashbord' ? '' : id);
	}

	return (
		<div className="sidebar">
			<section className="">
				<div className="siderbar-area">
					<ul>
						<li className="sidebar-logo" onClick={() => setActiveId('dashbord')}>
							<Link to="/"><img src="./images/logo_web.png" alt="logo" /> </Link>
						</li>
						<li onClick={() => setActiveId('dashbord')} className={currentPath == '/' ? "active" : ''}>
							<a href="/">Dashboard</a>
						</li>
						<li onClick={() => setActiveId('homeSub')}>
							<div className={currentPath === '/drug' || currentPath === '/procedure' || currentPath === '/guidelines' || currentPath === '/checklist' ? 'active' : 'deactive'}>
								<a href="#homeSub" data-toggle="collapse" aria-expanded="false">
									Pain Guidelines<i className="fa fa-angle-right course-down-ic" aria-hidden="true"></i>
								</a>
							</div>
							<ul className="collapse list-unstyled" id="homeSub">
								<li className={currentPath === '/drug' ? "active" : ''}><a href="/drug"><i className="fa fa-circle-o" aria-hidden="true"></i> Drugs</a></li>
								<li className={currentPath === '/procedure' ? "active" : ''}><a href="/procedure"><i class="fa fa-circle-o" aria-hidden="true"></i> Procedures</a></li>
								<li className={currentPath === '/guidelines' ? "active" : ''}><a href="/guidelines"><i class="fa fa-circle-o" aria-hidden="true"></i> View Full Guidelines</a></li>
								<li className={currentPath === '/checklist' ? "active" : ''}><a href="/checklist"><i class="fa fa-circle-o" aria-hidden="true"></i> View Checklist</a></li>
							</ul>
						</li>
						<li className={currentPath == '/feedback' ? "active" : ''} onClick={() => setActiveId('feedback')} id='feedback' Z >
							<a href="/feedback" >Feedback</a>
						</li>
						<li className={currentPath == '/setting' ? "active" : ''} onClick={() => setActiveId('setting')} >
							<a href="/setting" id='setting' >Settings</a>

						</li>
						<li>
							<Link onClick={logout} >Logout</Link>
							{/* <a href="#homeSub" onClick= {logout}>Logout</a> */}
						</li>
					</ul>
				</div>
			</section>
			{/* <Header /> */}




		</div>
	)
}

export default Sidebar