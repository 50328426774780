
import React, { useEffect, useState,useRef } from "react";
import Modal from 'react-modal';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Sidebar from '../Component/Sidebar';
import { userService } from '../_services';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';
import Loader from '../Component/Loader'
import Pagination from "react-js-pagination";
import ReactPaginate from 'react-paginate';
import Paginate from '../Component/Pagination';
import { CSVLink, CSVDownload } from "react-csv";
import XLSX from 'xlsx';
import { FilledInput } from "@material-ui/core";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import csv from 'csv';
import Papa from "papaparse";
import * as CSV from 'csv-string';
const punycode = require('punycode');
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const Drug = (props) => {
    const [drugs, setDrugs] = useState([]);
    const [singleDrug, setSingleDrug] = useState('');
    const [search, setSearch] = useState('');
    const [dateFormet, setDateFormet] = useState('');
    const [timeFormet, setTimeFormet] = useState('');
    const [drugName, setDrugName] = useState('');
    const [brandName, setBrandName] = useState('');
    const [lowRistGuideline, setLowRiskGuideline] = useState('');
    const [moderateRiskGuideline, setModerateRiskGuideline] = useState('');
    const [highRiskGuideline, setHighRiskGuideline] = useState('');
    const [restartAfterProcedureMethod, setRestartAfterProcedureMethod] = useState('');
    const [summaryOfDrug, setSummaryOfDrug] = useState('');
    const [drugSummary, setDrugSummary] = useState({ value: "" });
    const [procedureOfReversal, setProcedureOfReversal] = useState('');
    const timezone = jstz.determine();
    const tz = timezone.name()
    const [isLoading, setisLoading] = useState(false);
    const [isDrugDisable, setIsDrugDisable] = useState(false);
    const [status, setStatus] = useState('');
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [size, setSize] = useState(10);
    const [drugCSVHeaders, setDrugCSVHeaders] = useState([]);
    const [drugCSVData, setDrugCSVData] = useState([]);
    const [batchFileData, setBatchFileData] = useState([]);
    const [batchFileError, setBatchFileError] = useState('');
    const [batchFileName, setBatchFileName] = useState('');
    const [csvFile, setCsvFile] = useState('');
    const [isBatchFileValid, setIsBatchFileValid] = useState(false);
    const [modalIsOpendelete, setIsOpenedelete] = useState(false);
    const [isDrugEmpty, setIsDrugEmpty] = useState(false);
    const [isSummaryEmpty, setIsSummaryEmpty] = useState(false);
    const [isDrugChecked, setIsDrugChecked] = useState(false);
    const [drugDeleteArray, setDrugDeleteArray] = useState([]);
    const [tempVar, setTempVar] = useState(1);

    const uploadFile = useRef(null);
    useEffect(() => {
        getDrugList('', 0, size);
    }, []);

    const getDrugList = (searchTxt, pageNo, size) => {
        setisLoading(true);
        userService.getDrugs(searchTxt, pageNo, size).then(function (response) {
            var tempArry = [];
            response.data.all_drugs.map((drug, index) => {
                // setTempVar(0);
                drug.isDrugChecked = drugDeleteArray.includes(drug._id) ? true : false;
                tempArry.push(drug);
                // setTimeout(() => {
                //     setTempVar(1);
                // }, 0);
            })
            setDrugs(tempArry);
            // setDrugs(response.data.all_drugs);
            setPageCount(response.data.result_count);
            setisLoading(false);

        }).catch(function (error) {
            setDrugs([]);
            setisLoading(false);
            userService.handleError(error);
            // toast.error(error.response.data.message);
        });
    }






    const handlePageChange = (page) => {
        setTempVar(0);
        setPage(page);
        getDrugList('', page, size);
        setTimeout(() => {
            setTempVar(1);
        }, 10);
        // var tempArry = [];
        // drugs.map((drug, index) => {
        //     drug.isDrugChecked = drugDeleteArray.includes(drug._id) ? true : false;
        //     tempArry.push(drug);
        // })
    };

    function onSearchTextChange(value) {
        setSearch(value);
        getDrugList(value, 0, size);
        setPage(0);

    }

    const submit = () => {
        if (drugName === '' && drugSummary.value === '') {
            toast.error("Please enter drug name or summary atleast");

        }
        //  else if (brandName === '') {
        //     toast.error("Please Enter brand name");
        // } else if (lowRistGuideline === '') {
        //     toast.error("Please Enter low risk guideline");
        // } else if (moderateRiskGuideline === '') {
        //     toast.error("Please Enter moderate risk guideline");
        // } else if (highRiskGuideline === '') {
        //     toast.error("Please Enter high risk guideline");
        // } else if (restartAfterProcedureMethod === '') {
        //     toast.error("Please Enter restart after procedure");
        // }
        //  else if (!handleRestartAfterProcedure()) {
        //      console.log('error');
        //     // toast.error("Invalid restart after procedure method");
        // }
        // else if (drugSummary.value === '<p></p>' || drugSummary.value === '') {
        //     toast.error("Please Enter summary of drug");
        // }
        //  else if (procedureOfReversal === '') {
        //     toast.error("Please Enter procedure of reversal");
        // } 
        else {
            let params = {
                'drug_name': drugName,
                'brand_name': brandName,
                'low_risk_guideline': lowRistGuideline,
                'moderate_risk_guideline': moderateRiskGuideline,
                'high_risk_guideline': highRiskGuideline,
                'restart_after_procedure_method': restartAfterProcedureMethod,
                'summary_of_drug': drugSummary.value,
                'procedure_of_reversal': procedureOfReversal
            };
            setisLoading(true);
            console.log(params);
            userService.addDrug(params).then(function (response) {
                setisLoading(false);
                toast.success(response.data.message);
                setTimeout(() => {
                    window.location.href = '/drug';
                }, 1000);
                setIsOpenadddrug(false);
                getDrugList('', 0, size);
            }).catch(function (error) {
                if (error.response) {
                    // toast.error(error.response.data.message)
                    userService.handleError(error);
                }
                setisLoading(false);
            });
        }
    }

    const editDrug = () => {
        if (drugName === '' && drugSummary.value === '') {
            // toast.error("Please enter drug name");
            toast.error("Please enter drug name or summary atleast");
        }
        // else if (brandName === '') {
        //     toast.error("Please Enter brand name");
        // } else if (lowRistGuideline === '') {
        //     toast.error("Please Enter low risk guideline");
        // } else if (moderateRiskGuideline === '') {
        //     toast.error("Please Enter moderate risk guideline");
        // } else if (highRiskGuideline === '') {
        //     toast.error("Please Enter high risk guideline");
        // } else if (restartAfterProcedureMethod === '') {
        //     toast.error("Please Enter restart after procedure");
        // }
        // else if (/[^0-9a-zA-Z]/.test(restartAfterProcedureMethod)) {
        //     toast.error("Invalid restart after procedure method");
        // }

        // else if (drugSummary.value === '') {
        //     toast.error("Please Enter summary of drug");
        // }
        //  else if (procedureOfReversal === '') {
        //     toast.error("Please enter procedure for reversal");
        // }
        else {
            let params = {
                'drug_id': singleDrug._id,
                'drug_name': drugName,
                'brand_name': brandName,
                'low_risk_guideline': lowRistGuideline,
                'moderate_risk_guideline': moderateRiskGuideline,
                'high_risk_guideline': highRiskGuideline,
                'restart_after_procedure_method': restartAfterProcedureMethod,
                'summary_of_drug': drugSummary.value,
                'procedure_of_reversal': procedureOfReversal
            };
            setisLoading(true);
            console.log(params);
            userService.editDrug(params).then(function (response) {
                setisLoading(false);
                toast.success(response.data.message);
                setIsOpenedit(false);
                getDrugList('', page, size);
            }).catch(function (error) {
                if (error.response) {
                    userService.handleError(error);
                    // toast.error(error.response.data.message)
                }
                setisLoading(false);
            });
        }
    }

    var subtitle;
    const [modalIsOpenedit, setIsOpenedit] = useState(false);
    function openModaledit(data) {
        setSingleDrug(data);
        setIsOpenedit(true);
        setDrugName(data.drug_name);
        setBrandName(data.brand_name);
        setLowRiskGuideline(data.low_risk_guideline);
        setModerateRiskGuideline(data.moderate_risk_guideline);
        setHighRiskGuideline(data.high_risk_guideline);
        setRestartAfterProcedureMethod(data.restart_after_procedure_method);
        setSummaryOfDrug(data.summary_of_drug);
        let value = data.summary_of_drug;
        setDrugSummary({ value });
        setProcedureOfReversal(data.procedure_of_reversal);
        setIsOpenviewfeedback(false);
    }

    function afterOpenModaledit() {
        subtitle.style.color = '#f00';
    }

    function closeModaledit() {
        setIsOpenedit(false);
    }

    var subtitle;
    const [modalIsOpenfeedback, setIsOpenfeedback] = useState(false);
    function openModalfeedback() {
        setIsOpenfeedback(true);
        setIsOpenedit(false);
    }

    function afterOpenModalfeedback() {
        subtitle.style.color = '#f00';
    }

    function closeModalfeedback() {
        setIsOpenfeedback(false);
    }

    var subtitle;
    const [modalIsOpenviewfeedback, setIsOpenviewfeedback] = useState(false);
    function openModalviewfeedback(data) {
        const dateToFormat = data.createdAt;
        setTimeFormet(dateToFormat);
        var aa = moment.tz(data.createdAt, tz)
        setTimeFormet(aa);
        setSingleDrug(data);
        // document.getElementById('view-drug-summary')?.innerHTML = data.summary_of_drug;
        setIsOpenviewfeedback(true);
        setStatus(data.status);
        setIsDrugDisable(data.status === 'ACT' ? true : false)
    }

    function afterOpenModalviewfeedback() {
        subtitle.style.color = '#f00';
    }

    function closeModalviewfeedback() {
        setIsOpenviewfeedback(false);
    }

    var subtitle;
    const [modalIsOpenadddrug, setIsOpenadddrug] = useState(false);
    function openModaladddrug() {
        setDrugName('');
        setBrandName('');
        setLowRiskGuideline('');
        setModerateRiskGuideline('');
        setHighRiskGuideline('');
        setRestartAfterProcedureMethod('');
        setSummaryOfDrug('');
        let value = '';
        setDrugSummary({ value });
        setProcedureOfReversal('');
        setIsOpenadddrug(true);
    }

    function afterOpenModaladddrug() {
        subtitle.style.color = '#f00';
    }

    function closeModaladddrug() {
        setIsOpenadddrug(false);
    }
    var subtitle;
    const [modalIsOpencvs, setIsOpencvs] = useState(false);
    function openModalcvs() {
        let tmp_data = [];
        let tmp_headers = [
            { label: "Drug Name", key: "name" },
            { label: "Brand Name", key: "brand_name" },
            { label: "Low-Risk Guideline", key: "low_risk_guideline" },
            { label: "Intermediate-Risk Guideline", key: "moderate_risk_guideline" },
            { label: "High-Risk Guideline", key: "high_risk_guideline" },
            { label: "Restart after procedure", key: "restart_after_procedure_method" },
            { label: "Procedure for Reversal", key: "procedure_of_reversal" },
            { label: "Summary", key: "summary_of_drug" }
        ];
        for (var i = 0; i < 10; i++) {
            if (drugs[i]) tmp_data.push(
                {
                    name: drugs[i].drug_name,
                    brand_name: drugs[i].brand_name,
                    low_risk_guideline: drugs[i].low_risk_guideline,
                    moderate_risk_guideline: drugs[i].moderate_risk_guideline,
                    high_risk_guideline: drugs[i].high_risk_guideline,
                    restart_after_procedure_method: drugs[i].restart_after_procedure_method,
                    procedure_of_reversal: drugs[i].procedure_of_reversal,
                    summary_of_drug: drugs[i].summary_of_drug
                });
        }
        setDrugCSVData(tmp_data);
        setDrugCSVHeaders(tmp_headers);
        setIsOpencvs(true);
    }


    const onDrop = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            csv.parse(reader.result, (err, data) => {
                console.log(data);
            });
        };

        reader.readAsBinaryString(e.target.files[0]);
    }



    const handleBatchUpload = (e) => {

        if (e.target.files[0].size > 5e6) {
            toast.error("File size is greater then 500 MB");
        } else {
            console.log(drugCSVHeaders.length);
            setBatchFileError('');
            setBatchFileData([]);
            setBatchFileName(e.target.files[0].name);
            setCsvFile(e.target.files[0]);
            e.preventDefault();
            var isValid = true;
            var isAnyError = false;
            var files = e.target.files, f = files[0];
            var reader = new FileReader();
            reader.readAsText(e.target.files[0],'UTF-8');
            reader.onload = function (e) {
                var data = e.target.result;
                var data_arry = [];
                var file_data = [];
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
                data_arry = XLSX.utils.sheet_to_json(ws, { header: 1 });

                if (data_arry.length == 1) {
                    toast.error("Invalid CSV data. Please follow the sample CSV file");
                }
                data_arry.some(function (item, index) {
                    if (index == 0 || isAnyError) {
                        if (item[0] != 'Drug Name' || item[0] === undefined) {
                            
                            toast.error("Invalid CSV data. Please follow the sample CSV file");
                            isAnyError = true;
                            isValid = false;
                            return true;
                        }
                        else {
                            if (item[0] != 'Drug Name' || item[1] != 'Brand Name' || item[2] != 'Low-Risk Guideline' || item[3] != 'Intermediate-Risk Guideline' || item[4] != 'High-Risk Guideline' || item[5] != 'Restart after procedure' || item[6] != 'Procedure for Reversal' || item[7] != 'Summary') {
                                
                                toast.error("Invalid CSV data. Please follow the sample CSV file");
                                isAnyError = true;
                                isValid = false;
                                return true;
                            }
                            return;
                        }
                    }
                    if ((!item[0] || item[0].trim() == '') && (!item[7] || item[7].trim() == '')) {
                        // toast.error("Invalid CSV data. Please follow the sample CSV file");
                        toast.error("Please enter drug name or summary atleast");
                        isValid = false;
                        return true;
                    }
                    // file_data.push({ 'drug_name': item[0], 'brand_name': item[1], 'low_risk_guideline': item[2], 'moderate_risk_guideline': item[3], 'high_risk_guideline': item[4], 'restart_after_procedure_method': item[5], 'summary_of_drug': item[6], 'procedure_of_reversal': item[7] });
                    let item7 = item[7] ? item[7].replace(/\u0099/gi, '™') : "";
                    item7 = item7.replace(/\u0092s/gi, "'s")
                    file_data.push({ 'drug_name': item[0] ? item[0] : '', 'brand_name': item[1] ? item[1] : '', 'low_risk_guideline': item[2] ? item[2] : '', 'moderate_risk_guideline': item[3] ? item[3] : '', 'high_risk_guideline': item[4] ? item[4] : '', 'restart_after_procedure_method': item[5] ? item[5] : '', 'procedure_of_reversal': item[6] ? item[6] : '', 'summary_of_drug': item7 });
                }, []);
                setIsBatchFileValid(isValid);
                setBatchFileData(file_data);
                if (!isValid) {
                    setBatchFileName('');
                    setCsvFile('')
                }
            };
            // reader.readAsBinaryString(f);
        }
    }

    function validateBatchUpload() {
        if (batchFileData.length == 0 || !isBatchFileValid) {
            toast.error('Please select a csv file.')
            return false;
        } else {

            var formdata = new FormData();
            formdata.append('drug_csv', csvFile);
            let params = {
                'drug_csv': batchFileData,
            };
            setisLoading(true);
            console.log(params);
            userService.uploadDrugCsv(formdata).then(function (response) {
                setisLoading(false);
                toast.success(response.data.message);
                setBatchFileData([]);
                setBatchFileName('');
                setIsOpencvs(false);
                setTimeout(() => {
                    window.location.href = '/drug';
                }, 1000);
            }).catch(function (error) {
                if (error.response) {
                    // toast.error(error.response.data.message)
                    userService.handleError(error);
                }
                setisLoading(false);
            });
        }
    }

    function afterOpenModalcvs() {
        subtitle.style.color = '#f00';
    }

    function closeModalcvs() {
        setIsOpencvs(false);
        setBatchFileName('');
        setBatchFileData([]);
    }

    function handleStatus(id, status) {
        setStatus(status)
        let tmpStatus = status === 'ACT' ? 'DIS' : 'ACT'
        let params = {
            drug_id: id,
            drug_status: tmpStatus
        };
        setisLoading(true);
        userService.enableDisableDrug(params).then(function (response) {
            setSingleDrug(response.data.drug)
            setIsDrugDisable(!isDrugDisable);
            getDrugList('', page, size);
            setisLoading(false);
        }).catch(function (error) {
            setisLoading(false);
            userService.handleError(error);
            // toast.error(error.response.data.message);
        });
    }

    function handleSummary(value) {
        setDrugSummary({ value });
    }

    const [deletedData, setDeletedData] = useState('');
    function closeModaldelete() {
        setIsOpenedelete(false);
    }

    function afterOpenModalDelete() {
        subtitle.style.color = '#f00';
    }

    function openModaldelete(data) {
        setDeletedData(data);
        setIsOpenedelete(true);
    }

    function deleteDrug(id) {
        setisLoading(true);
        let params = {
            drug_id: id,
            drug_status: 'DEL'
        };
        userService.enableDisableDrug(params).then(function (response) {
            closeModaldelete();
            setisLoading(false);
            toast.success("Drug deleted successfully")
            getDrugList('', page, size);
        }).catch(function (error) {
            userService.handleError(error);
            // toast.error(error.response.data.message);
            setisLoading(false);
        });
    }

    const handleCheckChieldElement = (e, id, index) => {
        setIsDrugChecked(e.target.checked);
        if (e.target.checked === true) {
            drugDeleteArray.push(id);
            console.log(drugDeleteArray);
        }
        if (e.target.checked === false) {
            var array = drugDeleteArray;
            var indx = array.indexOf(e.target.checked);
            console.log(array.includes(id));
            let abc = array.filter((item) => {
                return item != id;
            })
            // array.splice(index, 1);
            setDrugDeleteArray(abc);
        }
    }

    const handleAllChecked = (event, drugs) => {
        setTempVar(0);
        let data = drugs
        data.forEach((drug) => {
            drug.isDrugChecked = event.target.checked
        })
        console.log(data)
        setDrugs(data);
        if (event.target.checked === true) {
            var tempArry = [];
            data.forEach((drug) => {
                tempArry.push(drug._id);
            })
            console.log(tempArry);
            setDrugDeleteArray(tempArry);
            // drugDeleteArray.push(data._id);
        }
        if (event.target.checked === false) {
            setDrugDeleteArray([]);
        }
        setTimeout(() => {
            setTempVar(1);
        }, 0);
    }

    const deleteDrugArray = () => {
        if (drugDeleteArray.length > 0) {
            openModaldelete(drugDeleteArray);
        }
        else {
            alert("Please select drugs");
        }
        console.log(drugDeleteArray);
    }

    return (
        <div>
            {isLoading && <Loader />}
            <div className="Wrapper">
                <Sidebar />
                <div class="main">

                    <Header />
                    <div className="main-div">
                        <div className="drug-page-heading">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-1">
                                        <h3>Drugs</h3>
                                    </div>
                                    <div className="col-md-11 d-flex">
                                        <div class="input-group input-group-md category-search mr-4">
                                            <input type="text" class="form-control" aria-label="Large" aria-describedby="inputGroup-sizing-sm" onChange={(e) => onSearchTextChange(e.target.value)} placeholder="Search by name, brand name..." />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-lg"><i class="fa fa-search" aria-hidden="true"></i></span>
                                            </div>
                                        </div>
                                        <div className="d-flex cvs-new-bt">
                                            <a onClick={() => openModalcvs()} className="cvs-btn mr-4"><i class="fa fa-file-text-o mr-2" aria-hidden="true"></i> UPLOAD CSV</a>
                                            {/* <a onClick={() => openModalcvs()} className="cvs-btn mr-4"><i class="fa fa-file-text-o mr-2" aria-hidden="true"></i> UPLOAD CSV</a> */}

                                            <a onClick={() => openModaladddrug()} className="add-new-btn"><i class="fa fa-plus mr-2" aria-hidden="true"></i> ADD NEW</a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="category-list">
                            <div className="table-responsive">
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            {/* <th className=" text-center checkbox-drug"><input type="checkbox" onChange={(e) => handleAllChecked(e, drugs)} value="allchecked" className="form-check-input" id="exampleCheck1" />{drugDeleteArray.length > 0 && <a className="delete-btn" onClick={() => deleteDrugArray()}>Delete</a>}</th> */}
                                            <th className="text-center">#</th>
                                            <th className="name-th">Name</th>
                                            <th className="brand-th">Brand Name</th>
                                            <th className="add-th">Added On</th>
                                            <th className="status-th">Status</th>
                                            <th className="action-th">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tempVar > 0 && drugs.length > 0 ? drugs.map((drug, index) => {
                                            return (
                                                <tr>{drug.isDrugChecked}
                                                    {/* <td className="text-center checkbox-drug">{drug.isDrugChecked} <input onChange={(e) => handleCheckChieldElement(e, drug._id, index)} type="checkbox" class="form-check-input" id="exampleCheck1" value={drug.isDrugChecked} defaultChecked={drug.isDrugChecked}></input></td> */}
                                                    <td className="text-center">{(page * size) + (index + 1)}</td>
                                                    <td>{drug.drug_name === "" ? "--" : drug.drug_name}</td>
                                                    <td>{drug.brand_name === "" ? "--" : drug.brand_name}</td>
                                                    <td>{moment(drug.createdAt).format("DD MMMM, YYYY")}</td>
                                                    <td className={drug.status === 'ACT' ? "active-status" : 'deactive-status'}>{drug.status === 'ACT' ? 'Enabled ' : 'Disabled'}</td>
                                                    <td><div className="dropdown">
                                                        <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Select <i className="fa fa-angle-down ml-2" aria-hidden="true"></i>
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <a className="dropdown-item one-item" onClick={() => openModalviewfeedback(drug)}>View Drug</a>
                                                            <a className="dropdown-item second-item" onClick={() => openModaledit(drug)}>Edit Drug</a>
                                                            <a className="dropdown-item third-item drug-third-item" onClick={() => handleStatus(drug._id, drug.status)}>{drug.status == 'ACT' ? "Disable" : 'Enable'} Drug</a>
                                                            <a className="dropdown-item fourth-item" onClick={() => openModaldelete(drug)}>Delete Drug</a>
                                                        </div>
                                                    </div></td>
                                                </tr>
                                            );
                                        })
                                            :
                                            <tr><td colSpan={6} className="text-center"><b>{search ? "No Drugs found" : "No Drugs added yet"}</b></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </section>
                        <div className="pagination-list-area">
                            <div className="pagination">
                            </div>
                            {!isLoading ? <p>{(page * size) + 1} - {(page * size) + drugs.length} of {pageCount} Listing</p> : <p></p>}
                            <div className="pagination">
                            </div>
                            <Paginate count={pageCount} activePage={page} handlePageChange={(page) => handlePageChange(page)} perPageEntries={size} />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

            <Modal
                isOpen={modalIsOpenadddrug}
                onAfterOpen={afterOpenModaladddrug}
                onRequestClose={closeModaladddrug}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="edit-modal">
                    <div class="modal-header">
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Add New Drug</h2>
                        <button className="cross-btn" onClick={() => closeModaladddrug()}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div class="modal-body">
                        <div className="change-password-box">
                            <div className="passwor-change-filled bleeding_list1">
                                <div>
                                    <p style={{ color: 'red' }}>Note: Both drug name and summary are mandatory for the data to be shown on the app.</p>
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">Drug Name</label>
                                    <input type="text" class="form-control" value={drugName} onChange={e => setDrugName(e.target.value)} id="formGroupExampleInput" placeholder="Enter drug name" />
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">Brand Name</label>
                                    <input type="text" class="form-control" value={brandName} onChange={e => setBrandName(e.target.value)} id="formGroupExampleInput" placeholder="Enter brand name" />
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">Low Risk Guideline</label>
                                    <input type="text" class="form-control" value={lowRistGuideline} onChange={e => setLowRiskGuideline(e.target.value)} id="formGroupExampleInput" placeholder="Enter low risk guideline" />
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">Moderate Risk Guideline</label>
                                    <input type="text" class="form-control" value={moderateRiskGuideline} onChange={e => setModerateRiskGuideline(e.target.value)} id="formGroupExampleInput" placeholder="Enter moderate risk guideline" />
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">High Risk Guideline</label>
                                    <input type="text" class="form-control" value={highRiskGuideline} onChange={e => setHighRiskGuideline(e.target.value)} id="formGroupExampleInput" placeholder="Enter high risk guideline" />
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">Restart After Procedure</label>
                                    <input type="text" class="form-control" value={restartAfterProcedureMethod} onChange={e => setRestartAfterProcedureMethod(e.target.value)} id="formGroupExampleInput" placeholder="Enter restart after procedure" />
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">Summary</label>
                                    <ReactQuill
                                        theme="snow"
                                        value={drugSummary.value}
                                        onChange={handleSummary}
                                        placeholder={"Enter Summary"}
                                    // modules={modules}
                                    // formats={formats}
                                    />
                                    {/* <textarea class="form-control" value={summaryOfDrug} onChange={e => setSummaryOfDrug(e.target.value)} id="formGroupExampleInput" row="5" placeholder="Enter Summary" /> */}
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">Procedure For Reversal</label>
                                    <textarea class="form-control" value={procedureOfReversal} onChange={e => setProcedureOfReversal(e.target.value)} id="formGroupExampleInput" row="5" placeholder="Enter procedure for reversal" />
                                </div>
                            </div>
                            <p className="text-center add-drug-submit"><a onClick={() => submit()}>SUBMIT</a></p>
                        </div>
                    </div></div>
            </Modal>

            <Modal
                isOpen={modalIsOpenedit}
                onAfterOpen={afterOpenModaledit}
                onRequestClose={closeModaledit}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="edit-modal">
                    <div className="modal-header">
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Edit Drug</h2>
                        <button className="cross-btn" onClick={() => closeModaledit()}><i className="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div className="modal-body">
                        <div className="change-password-box">
                            <div className="passwor-change-filled bleeding_list1">
                                <div class="form-group">
                                    <label for="formGroupExampleInput">Drug Name</label>
                                    <input type="text" class="form-control" value={drugName} onChange={e => setDrugName(e.target.value)} id="formGroupExampleInput" placeholder="Enter drug name" />
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">Brand Name</label>
                                    <input type="text" class="form-control" value={brandName} onChange={e => setBrandName(e.target.value)} id="formGroupExampleInput" placeholder="Enter brand name" />
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">Low Risk Guideline</label>
                                    <input type="text" class="form-control" value={lowRistGuideline} onChange={e => setLowRiskGuideline(e.target.value)} id="formGroupExampleInput" placeholder="Enter low risk guideline" />
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">Moderate Risk Guideline</label>
                                    <input type="text" class="form-control" value={moderateRiskGuideline} onChange={e => setModerateRiskGuideline(e.target.value)} id="formGroupExampleInput" placeholder="Enter moderate risk guideline" />
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">High Risk Guideline</label>
                                    <input type="text" class="form-control" value={highRiskGuideline} onChange={e => setHighRiskGuideline(e.target.value)} id="formGroupExampleInput" placeholder="Enter high risk guideline" />
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">Restart After Procedure</label>
                                    <input type="text" class="form-control" value={restartAfterProcedureMethod} onChange={e => setRestartAfterProcedureMethod(e.target.value)} id="formGroupExampleInput" placeholder="Enter restart after procedure" />
                                </div>

                                <div class="foexamplerm-group">
                                    <label for="formGroupExampleInput">Summary</label>
                                    {/* <textarea class="form-control" value={summaryOfDrug} onChange={e => setSummaryOfDrug(e.target.value)} id="formGroupExampleInput" row="5" placeholder="Enter Summary" /> */}
                                    <h6 className="summry-new-box"><ReactQuill
                                        theme="snow"
                                        value={drugSummary.value}
                                        onChange={handleSummary}
                                        placeholder={"Enter Summary"}
                                        style={{ fontStyle: 'normal' }}
                                    // modules={modules}
                                    // formats={formats}
                                    /></h6>
                                </div>

                                <div class="form-group">
                                    <label for="formGroupExampleInput">Procedure For Reversal</label>
                                    <textarea class="form-control" value={procedureOfReversal} onChange={e => setProcedureOfReversal(e.target.value)} id="formGroupExampleInput" row="5" placeholder="Enter procedure for reversal" />
                                </div>
                            </div>
                            <p className="text-center add-drug-submit"><a onClick={() => editDrug()}>SUBMIT</a></p>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpenfeedback}
                onAfterOpen={afterOpenModalfeedback}
                onRequestClose={closeModalfeedback}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="edit-modal feedback-reply-modal">
                    <div className="modal-header">
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Reply to Feedback</h2>
                        <button className="cross-btn" onClick={() => closeModalfeedback()}><i className="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label for="formGroupExampleInput">Your Comments</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Enter your comments" rows="10"></textarea>
                        </div>
                        <h5 className="text-center feedback-submit-bt"><a href="#">SUBMIT</a></h5>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpenviewfeedback}
                onAfterOpen={afterOpenModalviewfeedback}
                onRequestClose={closeModalviewfeedback}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="edit-modal">
                    <div className="modal-header">
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>View Drug</h2>
                        <button className="cross-btn" onClick={() => closeModalviewfeedback()}><i className="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div className="modal-body" id="drug-view-modal">
                        <div className="row">
                            <div className="col-4 mb-4">
                                <p>Drug Name</p>
                                <h6>{singleDrug.drug_name == "" ? '--' : singleDrug.drug_name}</h6>
                            </div>
                            <div className="col-4 mb-4">
                                <p>Added On</p>

                                <h6>{moment(singleDrug.createdAt).format("DD MMMM, YYYY")}</h6>
                            </div>
                            <div className="col-4 mb-4 ">
                                <p>Status</p>
                                <h6 className={isDrugDisable ? "active-status" : 'deactive-status'}>{isDrugDisable ? "Enable" : "Disable"}</h6>
                            </div>
                            <div className="col-4 mb-4">
                                <p>Brand Name</p>
                                <h6>{singleDrug.brand_name == "" ? "--" : singleDrug.brand_name}</h6>
                            </div>

                            <div className="col-4 mb-4"></div>
                            <div className="col-4 mb-4"></div>

                            <div className="col-4 mb-4">
                                <p>Low Risk Guideline</p>
                                <h6>{singleDrug.low_risk_guideline == "" ? "--" : singleDrug.low_risk_guideline}</h6>
                            </div>
                            <div className="col-4 mb-4">
                                <p>Moderate Risk Guideline</p>
                                <h6>{singleDrug.moderate_risk_guideline == "" ? "--" : singleDrug.moderate_risk_guideline}</h6>
                            </div>
                            <div className="col-4 mb-4">
                                <p>High Risk Guideline</p>
                                <h6>{singleDrug.high_risk_guideline == "" ? "--" : singleDrug.high_risk_guideline}</h6>
                            </div>
                            <div className="col-4 mb-4">
                                <p>Restart After Procedure</p>
                                <h6>{singleDrug.restart_after_procedure_method == "" ? "--" : singleDrug.restart_after_procedure_method}</h6>
                            </div>
                            <div className="col-4 mb-4">
                                <p>Procedure For Reversal</p>
                                <h6>{singleDrug.procedure_of_reversal == '' ? "--" : singleDrug.procedure_of_reversal}</h6>
                            </div>
                        </div>
                        <p>Summary</p>
                        {/* <h6>{singleDrug.summary_of_drug}</h6> */}
                        <h6 className="summry-new-box"><ReactQuill
                            theme="snow"
                            value={singleDrug.summary_of_drug}
                            readOnly={true}
                        /></h6>
                        {/* <h6 id="view-drug-summary" > {singleDrug.summary_of_drug}</h6> */}


                    </div>
                    <div className="row disable-edit-btn text-center d-flex justify-content-center">
                        <div className="col-sm-5">
                            <a onClick={() => handleStatus(singleDrug._id, singleDrug.status)}>{isDrugDisable ? "DISABLE" : 'ENABLE'} DRUG</a>
                        </div>
                        <div className="col-sm-5 edit-drug-btn">
                            <a onClick={() => openModaledit(singleDrug)}>EDIT DRUG</a>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpencvs}
                onAfterOpen={afterOpenModalcvs}
                onRequestClose={closeModalcvs}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="edit-modal cvs-modal drug-csv">
                    <div class="modal-header">
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Upload Drug CSV File</h2>
                        <button className="cross-btn" onClick={() => closeModalcvs()}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div>

                    </div>
                    <div>
                        
                    </div>
                    <div class="modal-body text-center">
                        <div class="text-center">
                            <p className="m-2">File Name</p>
                            <p className="batch-file m-2">{batchFileName}</p>
                            {/* <p className="m-2">File Name</p> */}
                            {/* <p className="m-2">{batchFileName}</p> */}
                        </div>
                        <div>
                            {/* <input className='custom-input-3' type="file"  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> */}
                            
                            <input ref={uploadFile} className='custom-input-3' type="file" onChange={(e) => handleBatchUpload(e)} acceptCharset="ISO-8859-1" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                            {batchFileName ? <img src="./images/CSV.png"></img> : <img src="./images/plus.png"></img>}
                            <p className="m-2">Upload CSV File</p>
                            {/* {
                                drugs.length < 0 ? */}
                                    <a className='download-csv' href="/Drug-sample.csv" download="Drug-sample.csv"><i className="fa fa-download" aria-hidden="true"  ></i> Download CSV File Sample</a>
                                    {/* :
                                    <CSVLink data={drugCSVData} headers={drugCSVHeaders} className='download-csv' target="_blank" filename={"Drugs-sample.csv"}  > Download CSV File Sample</CSVLink>
                            } */}


                            {/* <a className="download-csv" href="">Download CSV File Sample</a> */}
                        </div>
                    </div>
                    <p className="text-center csv-submit"><a className="" onClick={() => validateBatchUpload()} >SUBMIT</a></p>
                    <p className="note-red" style={{ color: 'red' }}>Note: Both drug name and summary are mandatory for the data to be shown on the app.</p>
                    <br />
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpendelete}
                onAfterOpen={afterOpenModalDelete}
                onRequestClose={closeModaldelete}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="delete-modal">
                    <div className="modal-header" >
                        <h2 className="modal-heading" ref={_subtitle => (subtitle = _subtitle)}>Delete Drug</h2>
                        <button className="cross-btn" onClick={closeModaldelete}><img className="cross-image" src="./images/cross.png" /></button>
                    </div>
                    <div class="modal-body add-catergory-form">
                        <div className="col-md-12">
                            <div className="form-group">
                                {drugDeleteArray.length > 0 ? <p>Are you sure you want to delete selected drugs ?</p> : <p>Are you sure you want to delete "{deletedData.drug_name}" ?</p>}

                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="signup-button text-center mb-3">
                                {drugDeleteArray.length > 0
                                    ?
                                    <button className="btn btn-primary mr-2" onClick={() => deleteDrug(drugDeleteArray)}>Yesss</button>
                                    :
                                    <button className="btn btn-primary mr-2" onClick={() => deleteDrug(deletedData._id)}>Yes</button>
                                }

                                <button className="btn btn-secondary no_delete" onClick={() => closeModaldelete()}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Drug